<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title"[innerHTML]="'gestaousuarios'|translate">

        </h1>
        <div class="d-flex flex-wrap btn-group-custom justify-content-between" style="gap: 15px">
            <a href="javascript:void(0)"
               (click)="actionNew()"
               class="btn btn-group-custom-block btn-primary"
               type="button">
                <svg-icon src="assets/svg/add.svg"></svg-icon>
                <span class="ml-1">{{'adicionar'|translate}}</span>
            </a>
        </div>
    </div>

    <div class="row justify-content-between">
        <div class="col-12 col-md-4">
            <div class="input-material">
                <input id="tittle" class="form-control btn-group-custom-block" type="text" required maxlength="150"
                       [(ngModel)]="filter.keyword"
                />
                <label for="Tittle">{{'busca'|translate}}</label>
            </div>
        </div>

        <div class="col-12 col-md-2">
            <a href="javascript:void(0)"
               class="btn btn-group-custom-block btn-primary" type="button" (click)="filter.page = 1; onFilter();">
                <span>{{'buscar'|translate}}</span>
            </a>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'nome'|translate}}</th>
                <th scope="col">{{'email'|translate}}</th>
                <th class="w-10" scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="users?.results?.length === 0">
                <td colspan="7">
                    <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                </td>
            </tr>
            <tr *ngFor="let x of users?.results |
                    paginate: {itemsPerPage: users.pageSize,
                    currentPage: users.currentPage,
                    totalItems: users.totalCount}">
                <td>{{x?.name}}</td>
                <td>{{x?.email}}</td>
                <td>
                    <div class="d-flex">
                        <button (click)="actionEdit(x)">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDelete(x?.id)">
                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                        </button>
                    </div>

                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>

    <div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal_header">
                    <h3 class="header-title">
                        {{'usuario'|translate}}
                    </h3>
                    <a href="javascript:void('');"
                       class="btn "
                       type="button" (click)="closeModal('modalUser')">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>
                </div>
                <div class="modal-body modal-body--small">
                    <div class="row my-3">
                        <div class="col-12">
                            <div class="input-material">
                                <input id="nameUser" class="form-control" type="text"
                                       required [(ngModel)]="model.name" maxlength="150"/>
                                <label for="nameUser">  {{'nome'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="input-material">
                                <input id="userEmail" class="form-control" type="text"
                                       required [(ngModel)]="model.email" maxlength="150"/>
                                <label for="userEmail">{{'email'|translate}}</label>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-block btn-warning mt-4"
                            (click)="actionSave()">  {{'salvar'|translate}}</button>
                </div>
            </div>
        </div>
    </div>

</div>

