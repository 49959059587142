import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {
  CreateMetaGQL, CreateMetaInput,
  CreateUserInput,
  Meta,
  MetaItemPage, MetasGQL, MetasQuery, RemoveMetaGQL, RemoveMetaItemGQL,
  SearchMetaItemGQL, SearchMetaItemInput,
  SearchMetaItemQuery, UpdateMetaGQL, UpdateMetaInput, UpdateUserInput, User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-long-term-goals',
  templateUrl: './long-term-goals.component.html',
  styleUrls: ['./long-term-goals.component.scss']
})
export class LongTermGoalsComponent extends BaseComponent implements OnInit, OnDestroy {

  model: MetaItemPage = new MetaItemPage();
  modelFilter: SearchMetaItemInput = new SearchMetaItemInput();
  modelQuery: QueryRef<SearchMetaItemQuery>;
  language = 'pt';

  modelMeta: Meta = new Meta();

  modelGroup: Meta[] = [];
  modelGroupQuery: QueryRef<MetasQuery>;

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public userService: UserService,
              public searchMetaItemGQL: SearchMetaItemGQL,
              public metasGQL: MetasGQL,
              public createMetaGQL: CreateMetaGQL,
              public removeMetaGQL: RemoveMetaGQL,
              public updateMetaGQL: UpdateMetaGQL,
              public removeMetaItemGQL: RemoveMetaItemGQL,
              ) {
    super(router, loading, translate);
  }

  color = 'blue';

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.modelMeta.color = '#ffffff';
    this.modelFilter.page = 1;
    this.setPeriod();
    this.userService.language.subscribe({
      next: data => {
        if (!this.isNullOrUndefined(data)) {
          // this.page = 1;
          this.language = this.isNullOrUndefined(data) ? this.translate.currentLang : data;
          this.getModelGroup();
          this.getModel();
        }
      }, error: err => super.onError(err)
    });
    this.getModelGroup();
    this.getModel();
  }

  ngOnDestroy(): void {
    super.destroyModal('modalGroup');
    super.destroyModal('modalGroupList');
  }

  setPeriod() {
    this.modelFilter.start = moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format();
    this.modelFilter.end = moment().endOf('day').utcOffset(0, true).format();
    this.setDatePicker('picker-period', (start, end, label) => {
      this.modelFilter.start = start.utcOffset(0, true).format();
      this.modelFilter.end = end.utcOffset(0, true).format();
    });
  }

  getModel() {
    console.log('enviar language para serviço de lista: ' + this.language);
    this.modelQuery = this.searchMetaItemGQL.watch({input: this.modelFilter});
    this.modelQuery.valueChanges.subscribe(({data}) => {
      this.model = data.searchMetaItem as MetaItemPage;
    });
  }

  onFilter() {
    this.modelQuery.refetch({input: this.modelFilter});
  }

  pageChanged(event) {
    this.modelFilter.page = event;
    this.onFilter();
  }

  handlerColor($event: string) {
    this.modelMeta.color = $event;
  }

  actionSave() {
    const arrValidateFields = [
      {value: this.modelMeta.name, text: `${this.translate.instant('nome')}<br>`},
      {value: this.modelMeta.color, text: `${this.translate.instant('cor')}<br>`}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
      return;
    }
    if (super.isNullOrUndefined(this.modelMeta.id)) {
      this.actionCreated();
    } else {
      this.actionUpdate();
    }
  }

  actionEdit(o: Meta) {
    this.modelMeta = Object.assign({}, o);
    this.color = this.modelMeta.color;
    this.showModal('modalGroup');
  }

  actionCreated() {
    const data = new CreateMetaInput();
    data.name = this.modelMeta.name;
    data.color = this.modelMeta.color;
    this.createMetaGQL.mutate({input: data}).subscribe(() => {
      this.modelMeta = new Meta();
      this.onFilter();
      this.modelGroupQuery.refetch();
      this.closeModal('modalGroup');
    });
  }

  actionUpdate() {
    const data = new UpdateMetaInput();
    data.id = this.modelMeta.id;
    data.name = this.modelMeta.name;
    data.color = this.modelMeta.color;
    this.updateMetaGQL.mutate({input: data}).subscribe(() => {
      this.modelMeta = new Meta();
      this.onFilter();
      this.modelGroupQuery.refetch();
      this.closeModal('modalGroup');
    });
  }

  getModelGroup() {
    this.modelGroupQuery = this.metasGQL.watch();
    this.modelGroupQuery.valueChanges.subscribe(({data}) => {
      this.modelGroup = data.metas as Meta[];
    });
  }

  actionNewMeta() {
    this.modelMeta = new Meta();
    this.showModal('modalGroup');
  }

  actionDelete(id) {
    super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), 'Tem certeza que deseja remover este grupo?', () => {
      this.removeMetaGQL.mutate({id: id}).subscribe((result) => {
        this.onFilter();
        this.modelGroupQuery.refetch();
        super.showMessage(this.translate.instant('baseComponent.success'), 'O grupo foi removido com sucesso.', 'success');
      });
    });
  }

  actionDeleteMetaItem(id) {
    super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), 'Tem certeza que deseja remover essa meta?', () => {
      this.removeMetaItemGQL.mutate({id: id}).subscribe((result) => {
        this.onFilter();
        super.showMessage(this.translate.instant('baseComponent.success'), 'A meta foi removida com sucesso.', 'success');
      });
    });
  }
}
