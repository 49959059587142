import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';
import {DatePicked} from './model/date-picked';
import {BaseComponent} from '../../../base/base.component';

declare var $: any;

@Component({
    selector: 'app-date-picker-custom',
    templateUrl: './date-picker-custom.component.html',
    styleUrls: ['./date-picker-custom.component.scss']
})
export class DatePickerCustomComponent extends BaseComponent implements OnInit {

    @Input()
    id: string;

    @Input()
    name = 'date-range-picker-custom';

    @Input()
    class: string;

    @Input()
    start: Date;

    @Input()
    end: Date;

    @Output()
    selectDate: EventEmitter<DatePicked> = new EventEmitter<DatePicked>();

    constructor(public router: Router,
                public translate: TranslateService,
                public loading: NgxSpinnerService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        $(() => {
            this.setDatePicker(this.name, (start, end, label) => {
                this.selectDate.emit(new DatePicked(start, end));
            });
        });
    }

    setDatePicker(id: string, callback) {
        $('.' + id).daterangepicker({
            startDate: moment(this.start),
            cancelButtonClasses: 'btn-danger',
            singleDatePicker: this.isNullOrUndefined(this.end),
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    getMonthNames() {
        let arrMonth = [];
        switch (this.translate.currentLang) {
            case 'en':
                arrMonth = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];
                break;
            case 'es':
                arrMonth = [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Deciembre'
                ];
                break;
            default:
                arrMonth = [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ];
                break;
        }
        return arrMonth;
    }

    getDaysOfDayWeek() {
        let arr = [];
        switch (this.translate.currentLang) {
            case 'en':
                arr = [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat'
                ];
                break;
            case 'es':
                arr = [
                    'Dom',
                    'lun',
                    'mar',
                    'mie',
                    'jue',
                    'vie',
                    'Sab'
                ];
                break;
            default:
                arr = [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ];
                break;
        }
        return arr;
    }
}
