import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    Banner,
    CreateBannerGQL,
    GetBannersGQL,
    GetBannersQuery, RemoveBannerGQL,
    UserByKeywordQuery,
    UserPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {UserService} from "../../service/user.service";

declare var $: any;


@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends BaseComponent implements OnInit {
    model: Banner[] = [];
    modelQuery: QueryRef<GetBannersQuery>;
    language = 'pt';

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public getBannersGQL: GetBannersGQL,
                public removeBannerGQL: RemoveBannerGQL
                ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.language = this.translate.currentLang;
        this.userService.language.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data)) {
                    // this.page = 1;
                    this.language = this.isNullOrUndefined(data) ? this.translate.currentLang : data;
                    this.getModel();
                }
            }, error: err => super.onError(err)
        });
        this.getModel();
    }

    getModel() {
        console.log('enviar language para serviço de lista: ' + this.language);
        this.modelQuery = this.getBannersGQL.watch();
        this.modelQuery.valueChanges.subscribe(({data}) => {
            this.model = data.getBanners as Banner[];
        });
    }

    actionDelete(id) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), 'Tem certeza que deseja remover o banner?', () => {
            this.removeBannerGQL.mutate({id: id}).subscribe((result) => {
                this.modelQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), 'Banner removido com sucesso.', 'success');
            });
        });
    }

}
