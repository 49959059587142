import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AuthInput {
  code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  redirectUri: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Banner {
  bannerUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export class Contact {
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class Content {
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  gri: Scalars['String'];
  griGroup?: Maybe<Array<GriGroup>>;
  id: Scalars['String'];
  index: Scalars['String'];
  ods?: Maybe<Array<Ods>>;
  pag: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
  theme?: Maybe<Array<Theme>>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export class ContentPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Content>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CreateBannerInput {
  description: Scalars['String'];
  /** Enum pt-BR|es-ES */
  language: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export class CreateContactInput {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export class CreateContentInput {
  content: Scalars['String'];
  gri: Scalars['String'];
  index: Scalars['String'];
  ods?: InputMaybe<Array<CreateContentOdsInput>>;
  pag: Scalars['String'];
  title: Scalars['String'];
  /** Enum "material"|"gri" */
  type: Scalars['String'];
};

export class CreateContentOdsInput {
  id: Scalars['String'];
};

export class CreateGlobalReportingInput {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fatherId?: InputMaybe<Scalars['String']>;
  /** Enum pt-BR|es-ES */
  language: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  /** Enum material|global */
  type: Scalars['String'];
};

export class CreateGlobalReportingItemInput {
  GlobalReportingId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class CreateGriGroupContentInput {
  id: Scalars['String'];
};

export class CreateGriGroupInput {
  content?: InputMaybe<Array<CreateGriGroupContentInput>>;
  name: Scalars['String'];
};

export class CreateGriInput {
  color: Scalars['String'];
  description: Scalars['String'];
  groups?: InputMaybe<Array<CreateGriGroupInput>>;
  title: Scalars['String'];
};

export class CreateHighlightItemHighlightInput {
  id: Scalars['String'];
};

export class CreateHighlightItemInput {
  description: Scalars['String'];
  highlight?: InputMaybe<CreateHighlightItemHighlightInput>;
  title: Scalars['String'];
};

export class CreateMetaInput {
  color: Scalars['String'];
  name: Scalars['String'];
};

export class CreateMetaItemInput {
  context?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  doing?: InputMaybe<Scalars['String']>;
  doingYear?: InputMaybe<Scalars['Float']>;
  kpi?: InputMaybe<Scalars['String']>;
  meta: CreateMetaItemMetaInput;
  result?: InputMaybe<Scalars['String']>;
  resultYear?: InputMaybe<Scalars['Float']>;
  scope?: InputMaybe<Scalars['String']>;
  /** True for publish false for save */
  status?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export class CreateMetaItemMetaInput {
  id: Scalars['String'];
};

export class CreateOdInput {
  color: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['Float'];
};

export class CreateThemeContentInput {
  id: Scalars['String'];
};

export class CreateThemeInput {
  content: Array<CreateThemeContentInput>;
  description: Scalars['String'];
  name: Scalars['String'];
};

export class CreateUserInput {
  email: Scalars['String'];
  name: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class GlobalReporting {
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  father?: Maybe<GlobalReporting>;
  globalReportingItem?: Maybe<Array<GlobalReportingItem>>;
  id: Scalars['ID'];
  language: Scalars['String'];
  sons?: Maybe<Array<GlobalReporting>>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export class GlobalReportingItem {
  code?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  globalReporting: GlobalReporting;
  globalReportingItemImages?: Maybe<Array<GlobalReportingItemImage>>;
  id: Scalars['ID'];
  page?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export class GlobalReportingItemImage {
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export class Gri {
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<GriGroup>>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class GriGroup {
  content?: Maybe<Array<Content>>;
  createdAt: Scalars['DateTime'];
  gri?: Maybe<Gri>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class GriPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Gri>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Highlight {
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  itens?: Maybe<Array<HighlightItem>>;
  name?: Maybe<Scalars['String']>;
};

export class HighlightItem {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlight?: Maybe<Highlight>;
  id: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class Meta {
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  itens?: Maybe<Array<MetaItem>>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class MetaItem {
  context?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  doing?: Maybe<Scalars['String']>;
  doingYear?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  kpi?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  result?: Maybe<Scalars['String']>;
  resultYear?: Maybe<Scalars['Float']>;
  scope?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export class MetaItemPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<MetaItem>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Mutation {
  /** Create new banner */
  createBanner: Banner;
  /** Create new contact */
  createContact: Contact;
  createContent: Content;
  /** create new global report */
  createGLobalReport: GlobalReporting;
  /** Create new global report item */
  createGlobalreportItem: GlobalReportingItem;
  createGri: Gri;
  createHighlight: HighlightItem;
  createMeta: Meta;
  createMetaItem: MetaItem;
  /** Create new Ods */
  createOds: Ods;
  createTheme: Theme;
  /** Create new user */
  createUser: User;
  login: AuthType;
  /** Remove banner by id */
  removeBanner: DefaultMessage;
  /** Delete contacts by id */
  removeContact: Scalars['Boolean'];
  removeContent: DefaultMessage;
  /** delete global report by id */
  removeGlobalReport: Scalars['Boolean'];
  /** Remove global report item by id */
  removeGlobalReportingItem: Scalars['Boolean'];
  removeGri: DefaultMessage;
  removeHighlight: DefaultMessage;
  removeMeta: DefaultMessage;
  removeMetaItem: DefaultMessage;
  /** Remove ods By id */
  removeOds: Ods;
  removeTheme: DefaultMessage;
  /** Remove user by id */
  removeUser: DefaultMessage;
  /** Update banner by id */
  updateBanner: Banner;
  /** Update contact */
  updateContact: Contact;
  updateContent: Content;
  /** Update global report by id */
  updateGlobalReport: GlobalReporting;
  /** Update global report */
  updateGlobalReportingItem: GlobalReportingItem;
  updateGri: Gri;
  updateHighlight: Highlight;
  updateMeta: Meta;
  updateMetaItem: MetaItem;
  /** Update ods */
  updateOds: Ods;
  updateTheme: Theme;
  /** update user */
  updateUser: User;
};


export class MutationCreateBannerArgs {
  data: CreateBannerInput;
};


export class MutationCreateContactArgs {
  data: CreateContactInput;
};


export class MutationCreateContentArgs {
  data: CreateContentInput;
};


export class MutationCreateGLobalReportArgs {
  data: CreateGlobalReportingInput;
};


export class MutationCreateGlobalreportItemArgs {
  data: CreateGlobalReportingItemInput;
};


export class MutationCreateGriArgs {
  data: CreateGriInput;
};


export class MutationCreateHighlightArgs {
  data: CreateHighlightItemInput;
};


export class MutationCreateMetaArgs {
  data: CreateMetaInput;
};


export class MutationCreateMetaItemArgs {
  data: CreateMetaItemInput;
};


export class MutationCreateOdsArgs {
  data: CreateOdInput;
};


export class MutationCreateThemeArgs {
  data: CreateThemeInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationRemoveContactArgs {
  id: Scalars['String'];
};


export class MutationRemoveContentArgs {
  id: Scalars['String'];
};


export class MutationRemoveGlobalReportArgs {
  id: Scalars['String'];
};


export class MutationRemoveGlobalReportingItemArgs {
  id: Scalars['String'];
};


export class MutationRemoveGriArgs {
  id: Scalars['String'];
};


export class MutationRemoveHighlightArgs {
  id: Scalars['String'];
};


export class MutationRemoveMetaArgs {
  id: Scalars['String'];
};


export class MutationRemoveMetaItemArgs {
  id: Scalars['String'];
};


export class MutationRemoveOdsArgs {
  id: Scalars['String'];
};


export class MutationRemoveThemeArgs {
  id: Scalars['String'];
};


export class MutationRemoveUserArgs {
  id: Scalars['String'];
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerInput;
};


export class MutationUpdateContactArgs {
  data: UpdateContactInput;
};


export class MutationUpdateContentArgs {
  data: UpdateContentInput;
};


export class MutationUpdateGlobalReportArgs {
  data: UpdateGlobalReportingInput;
};


export class MutationUpdateGlobalReportingItemArgs {
  data: UpdateGlobalReportingItemInput;
};


export class MutationUpdateGriArgs {
  data: UpdateGriInput;
};


export class MutationUpdateHighlightArgs {
  data: UpdateHighlightItemInput;
};


export class MutationUpdateMetaArgs {
  data: UpdateMetaInput;
};


export class MutationUpdateMetaItemArgs {
  data: UpdateMetaItemInput;
};


export class MutationUpdateOdsArgs {
  data: UpdateOdInput;
};


export class MutationUpdateThemeArgs {
  data: UpdateThemeInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserInput;
};

export class Ods {
  color: Scalars['String'];
  content?: Maybe<Array<Content>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  number: Scalars['Float'];
};

export class Query {
  content: Content;
  contents: Array<Content>;
  contentsByType: Array<Content>;
  /** get me */
  currentUser: User;
  /** Return banner by id */
  getBanner: Banner;
  /** List all banners */
  getBanners: Array<Banner>;
  /** Return contact by id */
  getContact: Contact;
  /** List all contacts */
  getContacts: Array<Contact>;
  /** Return global report by id */
  getGlobalReport: GlobalReporting;
  /** Return global report item by id */
  getGlobalReportingItem: GlobalReportingItem;
  /** List all global report itens */
  getGlobalReportingItems: Array<GlobalReportingItem>;
  /** List all global reports */
  getGlobalReports: Array<GlobalReporting>;
  /** List all material themes */
  getMaterials: Array<GlobalReporting>;
  /** Return ods by id */
  getOds: Ods;
  /** List all odses */
  getOdses: Array<Ods>;
  /** Return user by id */
  getUser: User;
  /** Return user by email */
  getUserByEmail: User;
  /** List all users */
  getUsers: Array<User>;
  gri: Gri;
  griCodes: Array<Scalars['String']>;
  gris: Array<Gri>;
  highlight: Highlight;
  highlights: Array<Highlight>;
  meta: Meta;
  metaItem: MetaItem;
  metaItens: Array<MetaItem>;
  metas: Array<Meta>;
  searchContent: ContentPage;
  searchGri: GriPage;
  searchMetaItem: MetaItemPage;
  searchTheme: ThemePage;
  theme: Theme;
  themes: Array<Theme>;
  /** Return users by keyword */
  userByKeyword: UserPage;
};


export class QueryContentArgs {
  id: Scalars['String'];
};


export class QueryContentsByTypeArgs {
  code?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export class QueryGetBannerArgs {
  id: Scalars['String'];
};


export class QueryGetContactArgs {
  id: Scalars['String'];
};


export class QueryGetGlobalReportArgs {
  id: Scalars['String'];
};


export class QueryGetGlobalReportingItemArgs {
  id: Scalars['String'];
};


export class QueryGetOdsArgs {
  id: Scalars['String'];
};


export class QueryGetUserArgs {
  id: Scalars['String'];
};


export class QueryGetUserByEmailArgs {
  email: Scalars['String'];
};


export class QueryGriArgs {
  id: Scalars['String'];
};


export class QueryHighlightArgs {
  id: Scalars['String'];
};


export class QueryMetaArgs {
  id: Scalars['String'];
};


export class QueryMetaItemArgs {
  id: Scalars['String'];
};


export class QueryMetaItensArgs {
  id: Scalars['String'];
};


export class QuerySearchContentArgs {
  data: SearchContentInput;
};


export class QuerySearchGriArgs {
  data: SearchGriInput;
};


export class QuerySearchMetaItemArgs {
  data: SearchMetaItemInput;
};


export class QuerySearchThemeArgs {
  data: SearchThemeInput;
};


export class QueryThemeArgs {
  id: Scalars['String'];
};


export class QueryUserByKeywordArgs {
  keyword: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchContentInput {
  end: Scalars['DateTime'];
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  status?: InputMaybe<Scalars['Boolean']>;
  /** Enum 'material'|'gri' */
  type?: InputMaybe<Scalars['String']>;
};

export class SearchGriInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchMetaItemInput {
  end: Scalars['DateTime'];
  group?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  /** True for publish false for save */
  status?: InputMaybe<Scalars['Boolean']>;
};

export class SearchThemeInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class Theme {
  content?: Maybe<Array<Content>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class ThemePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Theme>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UpdateBannerInput {
  bannerUrl: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Enum pt-BR|es-ES */
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateContactInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export class UpdateContentInput {
  content?: InputMaybe<Scalars['String']>;
  gri?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  index?: InputMaybe<Scalars['String']>;
  ods?: InputMaybe<Array<CreateContentOdsInput>>;
  pag?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  /** Enum "material"|"gri" */
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateGlobalReportingInput {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fatherId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Enum pt-BR|es-ES */
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** Enum material|global */
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateGlobalReportingItemInput {
  GlobalReportingId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  page?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateGriInput {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<CreateGriGroupInput>>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateHighlightItemInput {
  description?: InputMaybe<Scalars['String']>;
  highlight?: InputMaybe<CreateHighlightItemHighlightInput>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateMetaInput {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateMetaItemInput {
  context?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  doing?: InputMaybe<Scalars['String']>;
  doingYear?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  kpi?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<CreateMetaItemMetaInput>;
  result?: InputMaybe<Scalars['String']>;
  resultYear?: InputMaybe<Scalars['Float']>;
  scope?: InputMaybe<Scalars['String']>;
  /** True for publish false for save */
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOdInput {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descrption?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Float']>;
};

export class UpdateThemeInput {
  content?: InputMaybe<Array<CreateThemeContentInput>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateUserInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class User {
  content?: Maybe<Array<Content>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metaItem?: Maybe<Array<MetaItem>>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<User>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type UserFragment = { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined };

export type DefaultMessageFragment = { message: string, status: number };

export type UserPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined }> };

export type ContentFragment = { id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined };

export type ContentPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> };

export type BannerFragment = { id: string, language: string, type: string, title: string, description: string, bannerUrl?: string | null | undefined };

export type OdsFragment = { id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined };

export type GriFragment = { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined };

export type GriPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> };

export type GriGroupFragment = { id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined };

export type MetaFragment = { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined };

export type MetaItemFragment = { id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined };

export type MetaItemPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> };

export type HighlightFragment = { id: string, name?: string | null | undefined, icon?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, itens?: Array<{ id: string, title: string, description: string, highlight?: { id: string } | null | undefined }> | null | undefined };

export type HighlightItemFragment = { id: string, title: string, description: string, highlight?: { id: string } | null | undefined };

export type ThemePageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> };

export type ThemeFragment = { id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined };

export type GetBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBannersQuery = { getBanners: Array<{ id: string, language: string, type: string, title: string, description: string, bannerUrl?: string | null | undefined }> };

export type GetBannerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBannerQuery = { getBanner: { id: string, language: string, type: string, title: string, description: string, bannerUrl?: string | null | undefined } };

export type CreateBannerMutationVariables = Exact<{
  input: CreateBannerInput;
}>;


export type CreateBannerMutation = { createBanner: { id: string, language: string, type: string, title: string, description: string, bannerUrl?: string | null | undefined } };

export type UpdateBannerMutationVariables = Exact<{
  input: UpdateBannerInput;
}>;


export type UpdateBannerMutation = { updateBanner: { id: string, language: string, type: string, title: string, description: string, bannerUrl?: string | null | undefined } };

export type RemoveBannerMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBannerMutation = { removeBanner: { message: string, status: number } };

export type SearchContentQueryVariables = Exact<{
  data: SearchContentInput;
}>;


export type SearchContentQuery = { searchContent: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> } };

export type ContentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ContentQuery = { content: { id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined } };

export type CreateContentMutationVariables = Exact<{
  input: CreateContentInput;
}>;


export type CreateContentMutation = { createContent: { id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined } };

export type UpdateContentMutationVariables = Exact<{
  input: UpdateContentInput;
}>;


export type UpdateContentMutation = { updateContent: { id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined } };

export type RemoveContentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveContentMutation = { removeContent: { message: string, status: number } };

export type ContentsByTypeQueryVariables = Exact<{
  code: Scalars['String'];
  type: Scalars['String'];
}>;


export type ContentsByTypeQuery = { contentsByType: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> };

export type GriCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GriCodesQuery = { griCodes: Array<string> };

export type SearchGriQueryVariables = Exact<{
  input: SearchGriInput;
}>;


export type SearchGriQuery = { searchGri: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined }> } };

export type GriQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GriQuery = { gri: { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined } };

export type CreateGriMutationVariables = Exact<{
  input: CreateGriInput;
}>;


export type CreateGriMutation = { createGri: { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined } };

export type UpdateGriMutationVariables = Exact<{
  input: UpdateGriInput;
}>;


export type UpdateGriMutation = { updateGri: { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> | null | undefined } };

export type RemoveGriMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveGriMutation = { removeGri: { message: string, status: number } };

export type HighlightsQueryVariables = Exact<{ [key: string]: never; }>;


export type HighlightsQuery = { highlights: Array<{ id: string, name?: string | null | undefined, icon?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, itens?: Array<{ id: string, title: string, description: string, highlight?: { id: string } | null | undefined }> | null | undefined }> };

export type CreateHighlightMutationVariables = Exact<{
  input: CreateHighlightItemInput;
}>;


export type CreateHighlightMutation = { createHighlight: { id: string, title: string, description: string, highlight?: { id: string } | null | undefined } };

export type UpdateHighlightMutationVariables = Exact<{
  input: UpdateHighlightItemInput;
}>;


export type UpdateHighlightMutation = { updateHighlight: { id: string, name?: string | null | undefined, icon?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, itens?: Array<{ id: string, title: string, description: string, highlight?: { id: string } | null | undefined }> | null | undefined } };

export type RemoveHighlightMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveHighlightMutation = { removeHighlight: { message: string, status: number } };

export type LoginMutationVariables = Exact<{
  code: Scalars['String'];
  redirectUri: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginMutation = { login: { token: string, user: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } } };

export type SearchMetaItemQueryVariables = Exact<{
  input: SearchMetaItemInput;
}>;


export type SearchMetaItemQuery = { searchMetaItem: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> } };

export type MetaItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MetaItemQuery = { metaItem: { id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined } };

export type MetasQueryVariables = Exact<{ [key: string]: never; }>;


export type MetasQuery = { metas: Array<{ id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined }> };

export type CreateMetaItemMutationVariables = Exact<{
  input: CreateMetaItemInput;
}>;


export type CreateMetaItemMutation = { createMetaItem: { id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined } };

export type UpdateMetaItemMutationVariables = Exact<{
  input: UpdateMetaItemInput;
}>;


export type UpdateMetaItemMutation = { updateMetaItem: { id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined } };

export type RemoveMetaItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveMetaItemMutation = { removeMetaItem: { message: string, status: number } };

export type CreateMetaMutationVariables = Exact<{
  input: CreateMetaInput;
}>;


export type CreateMetaMutation = { createMeta: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } };

export type UpdateMetaMutationVariables = Exact<{
  input: UpdateMetaInput;
}>;


export type UpdateMetaMutation = { updateMeta: { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } };

export type RemoveMetaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveMetaMutation = { removeMeta: { message: string, status: number } };

export type GetOdsesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOdsesQuery = { getOdses: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> };

export type SearchThemeQueryVariables = Exact<{
  input: SearchThemeInput;
}>;


export type SearchThemeQuery = { searchTheme: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined }> } };

export type CreateThemeMutationVariables = Exact<{
  input: CreateThemeInput;
}>;


export type CreateThemeMutation = { createTheme: { id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined } };

export type UpdateThemeMutationVariables = Exact<{
  input: UpdateThemeInput;
}>;


export type UpdateThemeMutation = { updateTheme: { id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined, ods?: Array<{ id: string, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined, isActive?: boolean | null | undefined }> | null | undefined }> | null | undefined } };

export type RemoveThemeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveThemeMutation = { removeTheme: { message: string, status: number } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } };

export type UserByKeywordQueryVariables = Exact<{
  page: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type UserByKeywordQuery = { userByKeyword: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined }> } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveUserMutation = { removeUser: { message: string, status: number } };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  photo
}
    `;
export const UserPageFragmentDoc = gql`
    fragment UserPage on UserPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const OdsFragmentDoc = gql`
    fragment Ods on Ods {
  id
  number
  name
  description
  color
  imageUrl
  isActive
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  type
  gri
  index
  title
  pag
  content
  status
  createdAt
  updatedAt
  deletedAt
  user {
    ...User
  }
  ods {
    ...Ods
  }
}
    ${UserFragmentDoc}
${OdsFragmentDoc}`;
export const ContentPageFragmentDoc = gql`
    fragment ContentPage on ContentPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const BannerFragmentDoc = gql`
    fragment Banner on Banner {
  id
  language
  type
  title
  description
  bannerUrl
}
    `;
export const GriGroupFragmentDoc = gql`
    fragment GriGroup on GriGroup {
  id
  name
  createdAt
  createdAt
  updatedAt
  content {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const GriFragmentDoc = gql`
    fragment Gri on Gri {
  id
  title
  description
  color
  createdAt
  updatedAt
  deletedAt
  groups {
    ...GriGroup
  }
}
    ${GriGroupFragmentDoc}`;
export const GriPageFragmentDoc = gql`
    fragment GriPage on GriPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Gri
  }
}
    ${GriFragmentDoc}`;
export const MetaFragmentDoc = gql`
    fragment Meta on Meta {
  id
  name
  color
  createdAt
  updatedAt
  deletedAt
}
    `;
export const MetaItemFragmentDoc = gql`
    fragment MetaItem on MetaItem {
  id
  title
  status
  context
  scope
  result
  resultYear
  kpi
  doing
  doingYear
  description
  createdAt
  updatedAt
  meta {
    ...Meta
  }
  user {
    ...User
  }
}
    ${MetaFragmentDoc}
${UserFragmentDoc}`;
export const MetaItemPageFragmentDoc = gql`
    fragment MetaItemPage on MetaItemPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...MetaItem
  }
}
    ${MetaItemFragmentDoc}`;
export const HighlightItemFragmentDoc = gql`
    fragment HighlightItem on HighlightItem {
  id
  title
  description
  highlight {
    id
  }
}
    `;
export const HighlightFragmentDoc = gql`
    fragment Highlight on Highlight {
  id
  name
  icon
  color
  createdAt
  itens {
    ...HighlightItem
  }
}
    ${HighlightItemFragmentDoc}`;
export const ThemeFragmentDoc = gql`
    fragment Theme on Theme {
  id
  name
  description
  createdAt
  updatedAt
  deletedAt
  content {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const ThemePageFragmentDoc = gql`
    fragment ThemePage on ThemePage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;
export const GetBannersDocument = gql`
    query GetBanners {
  getBanners {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBannersGQL extends Apollo.Query<GetBannersQuery, GetBannersQueryVariables> {
    document = GetBannersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBannerDocument = gql`
    query GetBanner($id: String!) {
  getBanner(id: $id) {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBannerGQL extends Apollo.Query<GetBannerQuery, GetBannerQueryVariables> {
    document = GetBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBannerDocument = gql`
    mutation CreateBanner($input: CreateBannerInput!) {
  createBanner(data: $input) {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBannerGQL extends Apollo.Mutation<CreateBannerMutation, CreateBannerMutationVariables> {
    document = CreateBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBannerDocument = gql`
    mutation UpdateBanner($input: UpdateBannerInput!) {
  updateBanner(data: $input) {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBannerGQL extends Apollo.Mutation<UpdateBannerMutation, UpdateBannerMutationVariables> {
    document = UpdateBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveBannerDocument = gql`
    mutation RemoveBanner($id: String!) {
  removeBanner(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveBannerGQL extends Apollo.Mutation<RemoveBannerMutation, RemoveBannerMutationVariables> {
    document = RemoveBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchContentDocument = gql`
    query SearchContent($data: SearchContentInput!) {
  searchContent(data: $data) {
    ...ContentPage
  }
}
    ${ContentPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchContentGQL extends Apollo.Query<SearchContentQuery, SearchContentQueryVariables> {
    document = SearchContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContentDocument = gql`
    query content($id: String!) {
  content(id: $id) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentGQL extends Apollo.Query<ContentQuery, ContentQueryVariables> {
    document = ContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContentDocument = gql`
    mutation CreateContent($input: CreateContentInput!) {
  createContent(data: $input) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContentGQL extends Apollo.Mutation<CreateContentMutation, CreateContentMutationVariables> {
    document = CreateContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContentDocument = gql`
    mutation UpdateContent($input: UpdateContentInput!) {
  updateContent(data: $input) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContentGQL extends Apollo.Mutation<UpdateContentMutation, UpdateContentMutationVariables> {
    document = UpdateContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveContentDocument = gql`
    mutation RemoveContent($id: String!) {
  removeContent(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveContentGQL extends Apollo.Mutation<RemoveContentMutation, RemoveContentMutationVariables> {
    document = RemoveContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContentsByTypeDocument = gql`
    query ContentsByType($code: String!, $type: String!) {
  contentsByType(code: $code, type: $type) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentsByTypeGQL extends Apollo.Query<ContentsByTypeQuery, ContentsByTypeQueryVariables> {
    document = ContentsByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GriCodesDocument = gql`
    query GriCodes {
  griCodes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GriCodesGQL extends Apollo.Query<GriCodesQuery, GriCodesQueryVariables> {
    document = GriCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchGriDocument = gql`
    query SearchGri($input: SearchGriInput!) {
  searchGri(data: $input) {
    ...GriPage
  }
}
    ${GriPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchGriGQL extends Apollo.Query<SearchGriQuery, SearchGriQueryVariables> {
    document = SearchGriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GriDocument = gql`
    query Gri($id: String!) {
  gri(id: $id) {
    ...Gri
  }
}
    ${GriFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GriGQL extends Apollo.Query<GriQuery, GriQueryVariables> {
    document = GriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGriDocument = gql`
    mutation CreateGri($input: CreateGriInput!) {
  createGri(data: $input) {
    ...Gri
  }
}
    ${GriFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGriGQL extends Apollo.Mutation<CreateGriMutation, CreateGriMutationVariables> {
    document = CreateGriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGriDocument = gql`
    mutation UpdateGri($input: UpdateGriInput!) {
  updateGri(data: $input) {
    ...Gri
  }
}
    ${GriFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGriGQL extends Apollo.Mutation<UpdateGriMutation, UpdateGriMutationVariables> {
    document = UpdateGriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveGriDocument = gql`
    mutation RemoveGri($id: String!) {
  removeGri(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveGriGQL extends Apollo.Mutation<RemoveGriMutation, RemoveGriMutationVariables> {
    document = RemoveGriDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HighlightsDocument = gql`
    query Highlights {
  highlights {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HighlightsGQL extends Apollo.Query<HighlightsQuery, HighlightsQueryVariables> {
    document = HighlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHighlightDocument = gql`
    mutation CreateHighlight($input: CreateHighlightItemInput!) {
  createHighlight(data: $input) {
    ...HighlightItem
  }
}
    ${HighlightItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHighlightGQL extends Apollo.Mutation<CreateHighlightMutation, CreateHighlightMutationVariables> {
    document = CreateHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHighlightDocument = gql`
    mutation UpdateHighlight($input: UpdateHighlightItemInput!) {
  updateHighlight(data: $input) {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHighlightGQL extends Apollo.Mutation<UpdateHighlightMutation, UpdateHighlightMutationVariables> {
    document = UpdateHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveHighlightDocument = gql`
    mutation RemoveHighlight($id: String!) {
  removeHighlight(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveHighlightGQL extends Apollo.Mutation<RemoveHighlightMutation, RemoveHighlightMutationVariables> {
    document = RemoveHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($code: String!, $redirectUri: String!, $email: String!) {
  login(data: {code: $code, redirectUri: $redirectUri, email: $email}) {
    user {
      ...User
    }
    token
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchMetaItemDocument = gql`
    query SearchMetaItem($input: SearchMetaItemInput!) {
  searchMetaItem(data: $input) {
    ...MetaItemPage
  }
}
    ${MetaItemPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchMetaItemGQL extends Apollo.Query<SearchMetaItemQuery, SearchMetaItemQueryVariables> {
    document = SearchMetaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetaItemDocument = gql`
    query MetaItem($id: String!) {
  metaItem(id: $id) {
    ...MetaItem
  }
}
    ${MetaItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MetaItemGQL extends Apollo.Query<MetaItemQuery, MetaItemQueryVariables> {
    document = MetaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetasDocument = gql`
    query Metas {
  metas {
    ...Meta
  }
}
    ${MetaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MetasGQL extends Apollo.Query<MetasQuery, MetasQueryVariables> {
    document = MetasDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMetaItemDocument = gql`
    mutation CreateMetaItem($input: CreateMetaItemInput!) {
  createMetaItem(data: $input) {
    ...MetaItem
  }
}
    ${MetaItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMetaItemGQL extends Apollo.Mutation<CreateMetaItemMutation, CreateMetaItemMutationVariables> {
    document = CreateMetaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMetaItemDocument = gql`
    mutation UpdateMetaItem($input: UpdateMetaItemInput!) {
  updateMetaItem(data: $input) {
    ...MetaItem
  }
}
    ${MetaItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMetaItemGQL extends Apollo.Mutation<UpdateMetaItemMutation, UpdateMetaItemMutationVariables> {
    document = UpdateMetaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMetaItemDocument = gql`
    mutation RemoveMetaItem($id: String!) {
  removeMetaItem(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMetaItemGQL extends Apollo.Mutation<RemoveMetaItemMutation, RemoveMetaItemMutationVariables> {
    document = RemoveMetaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMetaDocument = gql`
    mutation CreateMeta($input: CreateMetaInput!) {
  createMeta(data: $input) {
    ...Meta
  }
}
    ${MetaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMetaGQL extends Apollo.Mutation<CreateMetaMutation, CreateMetaMutationVariables> {
    document = CreateMetaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMetaDocument = gql`
    mutation UpdateMeta($input: UpdateMetaInput!) {
  updateMeta(data: $input) {
    ...Meta
  }
}
    ${MetaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMetaGQL extends Apollo.Mutation<UpdateMetaMutation, UpdateMetaMutationVariables> {
    document = UpdateMetaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMetaDocument = gql`
    mutation RemoveMeta($id: String!) {
  removeMeta(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMetaGQL extends Apollo.Mutation<RemoveMetaMutation, RemoveMetaMutationVariables> {
    document = RemoveMetaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOdsesDocument = gql`
    query GetOdses {
  getOdses {
    ...Ods
  }
}
    ${OdsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOdsesGQL extends Apollo.Query<GetOdsesQuery, GetOdsesQueryVariables> {
    document = GetOdsesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchThemeDocument = gql`
    query SearchTheme($input: SearchThemeInput!) {
  searchTheme(data: $input) {
    ...ThemePage
  }
}
    ${ThemePageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchThemeGQL extends Apollo.Query<SearchThemeQuery, SearchThemeQueryVariables> {
    document = SearchThemeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateThemeDocument = gql`
    mutation CreateTheme($input: CreateThemeInput!) {
  createTheme(data: $input) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateThemeGQL extends Apollo.Mutation<CreateThemeMutation, CreateThemeMutationVariables> {
    document = CreateThemeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateThemeDocument = gql`
    mutation UpdateTheme($input: UpdateThemeInput!) {
  updateTheme(data: $input) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateThemeGQL extends Apollo.Mutation<UpdateThemeMutation, UpdateThemeMutationVariables> {
    document = UpdateThemeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveThemeDocument = gql`
    mutation RemoveTheme($id: String!) {
  removeTheme(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveThemeGQL extends Apollo.Mutation<RemoveThemeMutation, RemoveThemeMutationVariables> {
    document = RemoveThemeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserByKeywordDocument = gql`
    query UserByKeyword($page: Int!, $keyword: String!) {
  userByKeyword(page: $page, keyword: $keyword) {
    ...UserPage
  }
}
    ${UserPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserByKeywordGQL extends Apollo.Query<UserByKeywordQuery, UserByKeywordQueryVariables> {
    document = UserByKeywordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: String!) {
  removeUser(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }