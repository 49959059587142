import {AfterViewInit, Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';


declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        $('#sidebar').addClass('is-hide');
        // this.loading.show();
        /*this.homeGQL.watch().valueChanges.subscribe(({data}) => {
            this.home = data.home as Home;
            this.loading.hide();
        });*/
    }

    ngAfterViewInit() {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    // this.btnNew = data.role === 'local' || data.role === 'admin';
                }
            }
        });
    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
    }

}



