import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../service/user.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    CreateMetaItemGQL,
    CreateMetaItemInput, GetBannerQuery,
    Meta,
    MetaItem, MetaItemGQL, MetaItemQuery,
    MetasGQL,
    MetasQuery, UpdateMetaItemGQL,
    UpdateMetaItemInput
} from '../../../../generated/graphql';
import {QueryRef} from "apollo-angular";
import moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-long-term-goals-detail',
    templateUrl: './long-term-goals-detail.component.html',
    styleUrls: ['./long-term-goals-detail.component.scss']
})
export class LongTermGoalsDetailComponent extends BaseComponent implements OnInit {

    paramId = '';
    Editor = ClassicEditor;
    model: MetaItem = new MetaItem();

    modelQuery: QueryRef<MetaItemQuery>;

    modelGroup: Meta[] = [];
    modelGroupQuery: QueryRef<MetasQuery>;

    constructor(public router: Router,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public metasGQL: MetasGQL,
                public metaItemGQL: MetaItemGQL,
                public createMetaItemGQL: CreateMetaItemGQL,
                public updateMetaItemGQL: UpdateMetaItemGQL,
    ) {
        super(router, loading, translate);
        this.model.meta = new Meta();
    }

    ngOnInit(): void {
        this.route.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id) && data.id !== 'new'){
                this.paramId = data.id;
                this.getMetaItemId();
            }
        });
        this.getModelGroup();
    }

    getModelGroup() {
        this.modelGroupQuery = this.metasGQL.watch();
        this.modelGroupQuery.valueChanges.subscribe(({data}) => {
            this.modelGroup = data.metas as Meta[];
        });
    }

    getMetaItemId() {
        this.modelQuery = this.metaItemGQL.watch({id: this.paramId});
        this.modelQuery.valueChanges.subscribe(({data}) => {
            this.model = Object.assign({}, data.metaItem);
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.meta?.id, text: `Grupo <br>`},
            {value: this.model.status, text: `Status <br>`},
            {value: this.model.title, text: `Título <br>`},
            {value: this.model.description, text: `Descrição <br>`},
            {value: this.model.context, text: `Contexto <br>`},
            {value: this.model.scope, text: `Escopo <br>`},
            {value: this.model.result, text: `Resultado <br>`},
            {value: this.model.kpi, text: `Acompanhamento do KPI <br>`},
            {value: this.model.doing, text: `O que faremos <br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreated();
        } else {
            this.actionUpdate();
        }
    }

    actionCreated() {
        const data: CreateMetaItemInput = new CreateMetaItemInput();
        data.meta = new Meta();
        data.meta.id = this.model.meta.id;
        data.status = this.model.status;
        data.title = this.model.title;
        data.description = this.model.description;
        data.context = this.model.context;
        data.scope = this.model.scope;
        data.result = this.model.result;
        data.kpi = this.model.kpi;
        data.doing = this.model.doing;
        data.resultYear = moment().year();
        data.doingYear = moment().year() + 1;
        this.createMetaItemGQL.mutate({input: data}).subscribe(() => {
            this.model = new MetaItem();
            super.showMessage(this.translate.instant('baseComponent.success'), 'Meta adicionada com sucesso.', 'success');
            this.router.navigate(['/long-term']);
        });
    }

    actionUpdate() {
        const data: UpdateMetaItemInput = new UpdateMetaItemInput();
        data.meta = new Meta();
        data.id = this.model.id;
        data.meta.id = this.model.meta.id;
        data.status = this.model.status;
        data.title = this.model.title;
        data.description = this.model.description;
        data.context = this.model.context;
        data.scope = this.model.scope;
        data.result = this.model.result;
        data.kpi = this.model.kpi;
        data.doing = this.model.doing;
        data.resultYear = this.model.resultYear;
        data.doingYear = this.model.doingYear;
        this.updateMetaItemGQL.mutate({input: data}).subscribe(() => {
            this.model = new MetaItem();
            super.showMessage(this.translate.instant('baseComponent.success'), 'Meta alterada com sucesso.', 'success');
            this.router.navigate(['/long-term']);
        });

    }
}
