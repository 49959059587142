<div class="body">
    <div class="header border-0">
        <div class="d-flex">
            <a href="javascript:history.back()" class="cursor-pointer">
                <svg-icon class="mr-5" src="assets/svg/back.svg"></svg-icon>
            </a>
            <h1 class="header-title">
                {{'metaslongoprazo'|translate}}
            </h1>
        </div>
    </div>
    <div class="container banner_container">
        <div class="page_padding">
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex flex-column">
                        <div class="input-material">
                            <select id="field1" class="form-control" name="type" [(ngModel)]="model.meta.id">
                                <option selected
                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let x of modelGroup" [ngValue]="x.id">{{x.name}}</option>
                            </select>
                            <label for="field1" class="mb-0 mr-3">{{'grupo'|translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card_beige">
                        <div class="d-flex justify-content-between align-items-center px-3">
                            <label class="mb-0 mr-3"><strong>Status Ativo</strong></label>
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="accept-terms2"
                                       [checked]="model?.status"
                                       [(ngModel)]="model.status"
                                >
                                <label class="custom-control-label" for="accept-terms2"></label>
                            </div>
                        </div>
                        <!--<div class="input-material">
                            <select id="field2" class="form-control" name="type" [(ngModel)]="model.status">
                                <option selected
                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option [ngValue]="true">Ativo</option>
                                <option [ngValue]="false">Inativo</option>
                            </select>
                            <label for="field2" class="mb-0 mr-3">Status</label>
                        </div>-->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex justify-content-between">
                        <a href="javascript:void(0)" class="btn btn-outline-primary w-40" type="button"
                           (click)="actionSave()">
                            <span class="ml-1">{{'salvar'|translate}}</span>
                        </a>
                        <a href="javascript:void(0)" class="btn btn-primary w-40" type="button">
                            <span class="ml-1">{{'publicar'|translate}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <hr />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-4">
                    <div class="d-flex flex-column">
                        <div class="input-material">
                            <input name="field3" id="field3" type="text" class="form-control" required maxlength="150" [(ngModel)]="model.title" />
                            <label for="field3" class="mb-0 mr-3">Título</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="d-flex flex-column">
                        <div class="input-material">
                            <input name="field4" id="field4" type="text" class="form-control" required maxlength="250" [(ngModel)]="model.description"/>
                            <label for="field4" class="mb-0 mr-3">Descrição</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row card_beige mt-3 pt-4">
                <div class="col-12">
                    <h3 class="card_beige-title">CONTEXTO</h3>
                    <div class="d-flex flex-column">
                        <ckeditor [(ngModel)]="model.context" [editor]="Editor"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="row card_beige mt4 pt-4">
                <div class="col-12">
                    <h3 class="card_beige-title">ESCOPO</h3>
                    <div class="d-flex flex-column">
                        <ckeditor [(ngModel)]="model.scope" [editor]="Editor"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="row card_beige mt4 pt-4">
                <div class="col-12">
                    <h3 class="card_beige-title">RESULTADOS EM 20xx</h3>
                    <div class="d-flex flex-column">
                        <ckeditor [(ngModel)]="model.result" [editor]="Editor"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="row card_beige mt4 pt-4">
                <div class="col-12">
                    <h3 class="card_beige-title">ACOMPANHAMENTO DO KPI</h3>
                    <div class="d-flex flex-column">
                        <ckeditor [(ngModel)]="model.kpi" [editor]="Editor"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="row card_beige mt4 pt-4">
                <div class="col-12">
                    <h3 class="card_beige-title">O QUE FAREMOS EM 20xx</h3>
                    <div class="d-flex flex-column">
                        <ckeditor [(ngModel)]="model.doing" [editor]="Editor"></ckeditor>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-5 mb-3">
            <div class="col-6">
                <a href="javascript:void(0)"  class="btn btn-block btn-outline-primary" type="button"
                   (click)="actionSave()">
                    <span class="ml-1">{{'salvar'|translate}}</span>
                </a>
            </div>
            <div class="col-6">
                <a href="javascript:void(0)" class="btn btn-block btn-primary" type="button">
                    <span class="ml-1">{{'publicar'|translate}}</span>
                </a>
            </div>
        </div>

    </div>
</div>
