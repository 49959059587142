<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="setLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="setLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="nav-item "[ngClass]="{'active':router.url === '/'}" >
            <a href="javascript:void('Home');" [routerLink]="['/']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/home.svg"></svg-icon>
                Home
            </a>
        </li>
        <li class="nav-item " [ngClass]="{'active':router.url === '/banner'}">
            <a href="javascript:void('Nota');" [routerLink]="['/banner']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon class="svg-white" src="assets/svg/banner.svg"></svg-icon>
                Banner
            </a>
        </li>

        <li class="nav-item " [ngClass]="{'active':router.url === '/highlight'}">
            <a href="javascript:void('Dashboard');" [routerLink]="['/highlight']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/highlights.svg"></svg-icon>
                Destaque
            </a>
        </li>

        <li class="nav-item " [ngClass]="{'active':router.url === '/theme'}">
            <a href="javascript:void('Nota');" [routerLink]="['/theme']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon class="svg-white" src="assets/svg/materials.svg"></svg-icon>
                Temas Materiais
            </a>
        </li>
        <li class="nav-item " [ngClass]="{'active':router.url === '/gri'}">
            <a href="javascript:void('Nota');" [routerLink]="['/gri']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon class="svg-white" src="assets/svg/global.svg"></svg-icon>
                Gri
            </a>
        </li>
        <li class="nav-item " [ngClass]="{'active':router.url === '/long-term'}">
            <a href="javascript:void('Nota');" [routerLink]="['/long-term']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon class="svg-white" src="assets/svg/goal.svg"></svg-icon>
                Metas a longo prazo
            </a>
        </li>
              <li class="nav-item "  [ngClass]="{'active':router.url === '/content'}">
            <a href="javascript:void('Nota');" [routerLink]="['/content']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon class="svg-white" src="assets/svg/content.svg"></svg-icon>
                Conteudo
            </a>
        </li>
        <li class="nav-item " [ngClass]="{'active':router.url === '/user'}">
            <a href="javascript:void('Nota');" [routerLink]="['/user']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon class="svg-white" src="assets/svg/usuario.svg"></svg-icon>
                Usuários
            </a>
        </li>

        <!--<li class="nav-item ">
            <a href="javascript:void('Nota');" [routerLink]="['/my-list']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <span class="lbl">{{'sidebar.minhalista' | translate}}</span>
            </a>
        </li>-->

    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" (click)="onLogout()">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
