import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {BannerComponent} from './views/banner/banner.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ContactComponent} from './views/contact/contact.component';
import {GriComponent} from './views/gri/gri.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ContentComponent} from './views/content/content.component';
import {LongTermGoalsComponent} from './views/long-term-goals/long-term-goals.component';
import {GriDetailComponent} from './views/gri/gri-detail/gri-detail.component';
import {HighlightsComponent} from './views/highlights/highlights.component';
import {BannerDetailComponent} from './views/banner/banner-detail/banner-detail.component';
import {ContentDetailComponent} from './views/content/content-detail/content-detail.component';
import {LongTermGoalsDetailComponent} from './views/long-term-goals/long-term-goals-detail/long-term-goals-detail.component';
import {ThemesComponent} from './views/themes/themes.component';

const routes: Routes = [
    {path: 'authenticate', component: AuthenticateComponent},
    {path: 'logout', component: LogoutComponent},
    {
        path: '', component: MainComponent,
        children: [
            {path: 'home', component: HomeComponent},
            {path: 'user', component: UserComponent},
            {path: 'content', component: ContentComponent},
            {path: 'content-detail/new', component: ContentDetailComponent},
            {path: 'content-detail/:id', component: ContentDetailComponent},
            {path: 'banner', component: BannerComponent},
            {path: 'banner-detail/:id', component: BannerDetailComponent},
            {path: 'crop', component: CropImageComponent},
            {path: 'contact', component: ContactComponent},
            {path: 'theme', component: ThemesComponent},
            {path: 'gri', component: GriComponent},
            {path: 'gri-detail', component: GriDetailComponent},
            {path: 'gri-detail/:id', component: GriDetailComponent},
            {path: 'dashboard', component: DashboardComponent},
            {path: 'highlight', component: HighlightsComponent},
            {path: 'long-term', component: LongTermGoalsComponent},
            {path: 'long-term/:id', component: LongTermGoalsDetailComponent},
            {path: ':id', component: HomeComponent},
            {path: '', component: HomeComponent}
        ],
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
