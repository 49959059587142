<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title">
            {{'temasmateriais'|translate}}
        </h1>
        <div class="d-flex flex-wrap btn-group-custom justify-content-between " style="row-gap: 15px">
            <a (click)="actionNew()" href="javascript:void(0)"
               class="btn btn-temas btn-group-custom btn-sextenary" type="button">
                <svg-icon class="svg-white mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span>{{'grupotemamaterial'|translate}}</span>
            </a>
            <a [routerLink]="['/content-detail/new']" href="javascript:void(0)"
               class="btn btn-group-custom btn-secondary" type="button">
                <svg-icon class="svg-primary mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'novoconteudo'|translate}}</span>
            </a>
        </div>
    </div>


    <div class="row justify-content-between">
        <div class="col-md-4">
            <div class="input-material">
                <input id="keyword" [(ngModel)]="filter.keyword" class="form-control" type="text"
                       required maxlength="150"/>
                <label for="keyword">{{'Título'|translate}}</label>
            </div>
        </div>
        <div class="col-md-2">
            <a href="javascript:void(0)" (click)="onFilter()"
               class="btn btn-primary btn-block justify-content-center align-items-center"
               type="button">
                <span>{{'buscar'|translate}}</span>
            </a>
        </div>

    </div>

    <div class="mb-5">
        <!-- Search -->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">{{'nome'|translate}}</th>
                    <th scope="col">GRI</th>
                    <th scope="col" class="w-10"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(listSearch.results)">
                    <td class="text-center py-4" colspan="6">{{'nenhumresultado'|translate}}</td>
                </tr>
                <tr *ngFor="let l of listSearch.results |
                    paginate: {itemsPerPage: listSearch.pageSize,
                    currentPage: listSearch.currentPage,
                    totalItems: listSearch.totalCount}">
                    <td>{{l.name}}</td>
                    <td>
                    <span *ngFor="let g of l.content,let i = index">
                        <span *ngIf="i>0">,</span>
                        {{g.gri}}
                    </span>
                    </td>
                    <td>
                        <button (click)="actionEdit(l)">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDelete(l?.id)">
                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalTema" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal_header">
                <h3 class="header-title">
                    {{isNullOrUndefined(theme.id) ? 'Novo Tema' : 'Editar Tema'}}
                </h3>
                <a (click)="closeModal('modalTema')" href="javascript:void('');"
                   class="btn "
                   type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row my-3">
                    <div class="col-12">
                        <div class="input-material">
                            <input id="reUser" [(ngModel)]="theme.name" class="form-control" type="text"
                                   required maxlength="15"/>
                            <label for="reUser">{{'nomedogrupo'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material mt-3">
                            <textarea id="description" [(ngModel)]="theme.description" class="form-control"
                                      type="text">
                            </textarea>
                            <label for="description">{{'descricao'|translate}}</label>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <span class="modalSub">{{'encontrequedesejaanexaraogrupo'|translate}}</span>
                        <div class="d-flex align-items-center mt-3" style="gap: 10px">
                            <div class="input-material mb-0 flex-grow-1">
                                <input id="searchCode" appOnlyNumber [(ngModel)]="code" (keyup)="getContent()"
                                       class="form-control"
                                       type="text"
                                       required maxlength="15"/>
                                <label for="searchCode">
                                    {{'buscacodigooutitulo'|translate}}
                                </label>
                                <div class="autocomplete-panel" *ngIf="code?.length > 0">
                                    <div *ngIf="content?.length === 0" class="theme_row text-center">
                                        <span>Nenhum item encontrado.</span>
                                    </div>
                                    <div (click)="addContent(c)" class="theme_row is-cursor"
                                         *ngFor="let c of content">
                                        <b>{{c.gri}}</b>
                                        <span>{{c.title}}</span>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <!--<a href="javascript:void(0)" (click)="getContent()" class="btn btn-primary">
                                <svg-icon src="assets/svg/look.svg"></svg-icon>
                            </a>-->
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="theme_row" *ngFor="let c of theme.content,let i = index">
                            <b>{{c.gri}}</b>
                            <span>{{c.title}}</span>
                            <a href="javascript:void(0)" (click)="removeContent(i)">
                                <svg-icon src="assets/svg/closer.svg"></svg-icon>
                            </a>
                        </div>
                    </div>

                </div>
                <button (click)="onSave()" class="btn btn-block btn-primary mt-3">{{'salvar'|translate}}</button>
            </div>

        </div>
    </div>
</div>
