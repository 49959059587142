import {AfterContentInit, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../base/base.component';
import {
    Content, ContentsByTypeGQL,
    CreateGriGQL, CreateGriGroupContentInput, CreateGriGroupInput, CreateGriInput,
    GetOdsesGQL, Gri,
    GriCodesGQL, GriGQL, GriGroup,
    Ods, UpdateGriGQL, UpdateGriInput
} from '../../../../generated/graphql';

@Component({
    selector: 'app-gri-detail',
    templateUrl: './gri-detail.component.html',
    styleUrls: ['./gri-detail.component.scss']
})
export class GriDetailComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    parmId: string;
    gri: Gri = new Gri();
    griGroup: GriGroup = new GriGroup();
    indexSelected: number;
    code: string = '';
    content: Content[] = [];

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public loading: NgxSpinnerService,
                public griGQL: GriGQL,
                public createGriGQL: CreateGriGQL,
                public updateGriGQL: UpdateGriGQL,
                public contentsByTypeGQL: ContentsByTypeGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('modalGri');
    }

    ngAfterContentInit(): void {
        this.gri.groups = [];
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url.id)) {
                this.parmId = url.id;
                this.griGQL.watch({id: url.id}).valueChanges.subscribe(({data}) => {
                    this.gri = JSON.parse(JSON.stringify(data.gri)) as Gri;
                }, error => super.onError(error));
            }
        });
    }

    getContent() {
        this.contentsByTypeGQL.watch({type: 'gri', code: this.code}).valueChanges.subscribe(({data}) => {
            this.content = data.contentsByType as Content[];
            // FILTRA OS SELECIONADOS
            this.actionFilterContent();
        }, error => super.onError(error));
    }

    actionFilterContent() {
        this.griGroup.content.forEach((e) => {
            this.content = this.content.filter((f) => {
                return e.id != f.id;
            });
        });
    }

    createGroup() {
        this.indexSelected = null;
        this.griGroup = new GriGroup();
        this.griGroup.content = [];
        this.getContent();
        this.showModal('modalGri');
    }

    editGroup(g: GriGroup, index: number) {
        this.indexSelected = index;
        this.griGroup = Object.assign({}, g);
        this.getContent();
        this.showModal('modalGri');
    }

    removeContent(index: number) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.griGroup.content.splice(index, 1);
            this.getContent();
        });
    }

    addContent(c: Content) {
        this.griGroup.content.push(c);
        this.actionFilterContent();
        this.code = '';
    }

    saveGroup() {
        const arrValidateFields = [
            {value: this.griGroup.name, text: `Nome da Categoria<br>`},
            {value: this.griGroup.content, text: `Um GRI<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.indexSelected)) {
            this.gri.groups.push(this.griGroup);
        } else {
            this.gri.groups[this.indexSelected] = this.griGroup;
        }
        this.closeModal('modalGri');
    }

    onSave() {
        const arrValidateFields = [
            {value: this.gri.title, text: `Titulo<br>`},
            {value: this.gri.description, text: `${this.translate.instant('descricao')}<br>`},
            {value: this.gri.color, text: `${this.translate.instant('color')}<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.gri.id)) {
            console.log('new');
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    createGriGroupContent(content: Content[]) {
        let createGriGroupContentInputs: CreateGriGroupContentInput[] = [];
        content.forEach((e) => {
            const c: CreateGriGroupContentInput = {
                id: e.id,
            };
            createGriGroupContentInputs.push(c);
        });
        return createGriGroupContentInputs;
    }

    createContentGroup() {
        let createGriGroupInputs: CreateGriGroupInput[] = [];
        this.gri.groups.forEach((e) => {
            const ods: CreateGriGroupInput = {
                name: e.name,
                content: this.createGriGroupContent(e.content),
            };
            createGriGroupInputs.push(ods);
        });
        return createGriGroupInputs;
    }

    actionUpdate() {
        const input: UpdateGriInput = {
            id: this.gri.id,
            title: this.gri.title,
            description: this.gri.description,
            color: this.gri.color,
            groups: this.createContentGroup(),
        };
        console.log(input);
        this.updateGriGQL.mutate({input}).subscribe(({data}) => {
            this.router.navigate(['/gri']);
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
        }, error => super.onError(error));
    }

    actionCreate() {
        const input: CreateGriInput = {
            title: this.gri.title,
            description: this.gri.description,
            color: this.gri.color,
            groups: this.createContentGroup(),
        };
        this.createGriGQL.mutate({input}).subscribe(({data}) => {
            this.router.navigate(['/gri']);
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningCreateSuccess'), 'success');
        }, error => super.onError(error));
    }

    removeGroup(index: number) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.gri.groups.splice(index, 1);
        });
    }
}
