<section class="single-page">
    <div class="btn-hero" [routerLink]="'/user'">
        <svg-icon src="assets/svg/options.svg"></svg-icon>
        <span>{{'gestao' | translate}}</span>
    </div>
    <div class="hero">
        <img src="assets/images/background.png" alt="Clic" />
    </div>

    <div class="list-featured">
        <div class="p-2">
            <a href="javascript:void('');" [routerLink]="'/banner'" class="item">
                <svg-icon class="svg-white" src="assets/svg/banner.svg"></svg-icon>
                <span>{{'banners'|translate}}</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" [routerLink]="'/highlight'" class="item">
                <svg-icon class="svg-white" src="assets/svg/highlights.svg"></svg-icon>
                <span>{{'destaques'|translate}}</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" [routerLink]="'/theme'" class="item">
                <svg-icon class="svg-white" src="assets/svg/materials.svg"></svg-icon>
                <span>{{'temamateriais'|translate}}</span>
            </a>
        </div>
    </div>
    <div class="list-featured mt-3">
        <div class="p-2">
            <a href="javascript:void('');" [routerLink]="'/gri'" class="item">
                <svg-icon class="svg-white" src="assets/svg/global.svg"></svg-icon>
                <span>GRI</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" [routerLink]="'/content'" class="item">
                <svg-icon class="svg-white" src="assets/svg/content.svg"></svg-icon>
                <span>{{'conteudo'|translate}}</span>
            </a>
        </div>
        <div class="p-2">
            <a href="javascript:void('');" [routerLink]="'/long-term'" class="item">
                <svg-icon class="svg-white" src="assets/svg/goal.svg"></svg-icon>
                <span>{{'metaslongoprazo'|translate}}</span>
            </a>
        </div>
    </div>
</section>
