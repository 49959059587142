<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title">
            {{'GRI'|translate}}
        </h1>
        <div class="d-flex btn-group-custom justify-content-between">
            <a href="javascript:void(0)" class="btn btn-group-custom btn-sextenary btn-gri" type="button">
                <svg-icon class="svg-white mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span [routerLink]="'/gri-detail'">{{'grupoGRI'|translate}}</span>
            </a>
            <a [routerLink]="['/content-detail/new']" href="javascript:void(0)"
               class="btn btn-group-custom btn-secondary" type="button">
                <svg-icon class="svg-primary mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'novoconteudo'|translate}}</span>
            </a>
        </div>
    </div>


    <div class="row justify-content-between">
        <div class="col-md-4">
            <div class="input-material">
                <input id="title" [(ngModel)]="filter.keyword" class="form-control" type="text" required
                       maxlength="150"/>
                <label for="title">{{'busca'|translate}}</label>
            </div>
        </div>


        <div class="col-md-2 buscar-gri">
            <a href="javascript:void(0)" (click)="onFilter()" class="btn btn-group-custom-block btn-primary"
               type="button">
                <span class="ml-1">{{'buscar'|translate}}</span>
            </a>
        </div>

    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">GRI</th>
                <th scope="col">{{'Grupos'|translate}}</th>
                <th scope="col" class="w-10"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(listSearch.results)">
                <td class="text-center py-4" colspan="6">{{'nenhumresultado'|translate}}</td>
            </tr>
            <tr *ngFor="let l of listSearch.results |
                    paginate: {itemsPerPage: listSearch.pageSize,
                    currentPage: listSearch.currentPage,
                    totalItems: listSearch.totalCount}">
                <td>{{l.title}}</td>
                <td>
                    <span *ngFor="let g of l.groups,let i = index">
                        <span *ngIf="i>0">,</span>
                        {{g.name}}
                    </span>
                </td>
                <td>
                    <div class="d-flex">
                        <button [routerLink]="['/gri-detail/' + l.id]">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDelete(l?.id)">
                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
</div>
