<section class="container">

    <div class="d-sm-flex align-items-center justify-content-between m-4">
        <h1 class="header-title">
            <svg-icon src="assets/svg/phone.svg"></svg-icon>
            {{'faleconosco'|translate}}
        </h1>
    </div>


    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-lg-3 col-xl-3 mt-3">
                    <div class="input-material">
                        <input id="period" class="form-control js-daterangepicker" type="text" required/>
                        <label for="period">{{'periodo'|translate}}</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-3 col-xl-3 mt-3">
                    <div class="input-material">
                        <input id="name" class="form-control" type="text" required />
                        <label for="name">{{'nome'|translate}}</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-3 col-xl-3 mt-3">
                    <div class="input-material">
                        <input id="email" class="form-control" type="text" required/>
                        <label for="email">{{'email'|translate}}</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 mt-3">
                    <div class="input-material">
                        <select id="profile" class="form-control" name="type">
                            <option selected [ngValue]="undefined">Todos</option>
                            <option [ngValue]="'awaiting'">{{'aguardandoresposta'|translate}}</option>
                            <option [ngValue]="'answered'">{{'respondido'|translate}}</option>
                        </select>
                        <label for="profile" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                        {{'exportarexcel'|translate}}
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                        {{'buscar'|translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3 mx-2 bg-white p-3">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="align-middle text-center">{{'opcoes'|translate}}</th>
                    <th class="align-middle text-center">Status</th>
                    <th class="align-middle w-10">{{'datahora'|translate}}</th>
                    <th class="align-middle">{{'nome'|translate}}</th>
                    <th class="align-middle w-20">{{'email'|translate}}</th>
                    <th class="align-middle">{{'telefone'|translate}}</th>
                    <th class="align-middle">{{'razaosocial'|translate}}</th>
                    <th class="align-middle">{{'codigocliente'|translate}}</th>
                    <th class="align-middle">CNPJ</th>
                    <th class="align-middle">{{'mensagem'|translate}}</th>
                </tr>
                </thead>
                <tbody>
             <!--   <tr>
                    <td colspan="10">
                        <p class="pt-5 pb-5 text-center">
                            Nenhum registro encontrado.
                        </p>
                    </td>
                </tr>-->
                <tr >
                    <td class="text-center">
                        <a (click)="showModal('modalContactDetail')" href="javascript:void('detail');" class="btn btn-sm btn-primary ml-2 mb-2">
                            <svg-icon class="svg-white"  src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                        <!--<a href="javascript:void('delete');" class="btn btn-sm btn-danger ml-2 mb-2"
                           (click)="actionRemove(r.id)">
                          <svg-icon class="svg-secondary" src="assets/svg/trash.svg"></svg-icon>
                        </a>-->
                    </td>
                    <td class="text-center">
                        <div class="badge badge-warning py-1 text-white size-md" >
                            Aguardando
                        </div>
                        <!--<div class="badge badge-success py-1 size-md">Respondido</div>-->
                    </td>
                    <td>27/02/1998</td>
                    <td>Caio Moraes</td>
                    <td>caiofelipem9@gmail.com</td>
                    <td>11975056693</td>
                    <td>Duka Digital</td>
                    <td>13.000</td>
                    <td>Caio</td>
                    <td>Teste</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center mt-3">
            <pagination-controls></pagination-controls>
        </div>
    </div>
</section>

<div class="modal-custom modal" id="modalContactDetail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalContactDetail"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>FALE CONOSCO</h3>
                <a href="javascript:void('Fechar');" class="close text-white" data-dismiss="modal"
                   aria-label="Close" >
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row mt-1 text-right">
                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <div class="badge badge-warning py-1 text-white size-md" >
                           {{'aguardando'|translate}}
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12 col-lg-5 offset-lg-1">
                        <p class="text-muted size-md"><strong>{{'nome'|translate}}:</strong><span
                                class="ml-2">Caio</span></p>
                        <p class="text-muted size-md"><strong>{{'email'|translate}}:</strong> <span
                                class="ml-2">Caio@duka.digital</span></p>
                    </div>

                    <div class="col-md-12 col-lg-6">
                        <p class="text-muted size-md"><strong>{{'datahora'|translate}}:</strong>
                            <span class="ml-2">21/01/2020</span></p>
                        <p class="text-muted size-md"><strong>{{'telefone'|translate}}:</strong><span
                                class="ml-2">11975056693</span></p>
                    </div>

                    <ng-container >
                        <div class="col-md-12 col-lg-10 offset-lg-1">
                            <hr>
                        </div>

                        <div class="col-md-12 col-lg-5 offset-lg-1">
                            <p class="text-muted size-md"><strong>{{'razaosocial'|translate}}:</strong><span
                                    class="ml-2">1351351348</span></p>
                        </div>

                        <div class="col-md-12 col-lg-6">
                            <p class="text-muted size-md"><strong>{{'codigocliente'|translate}}:</strong> <span
                                    class="ml-2">1538483154</span></p>
                            <p class="text-muted size-md"><strong>CNPJ</strong>
                                <span class="ml-2">153848615384</span></p>
                        </div>
                    </ng-container>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <p class="text-muted size-md"><strong>{{'mensagem'|translate}}:</strong><span
                                class="ml-2">{{'mensagem'|translate}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <hr/>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1 text-right">
                        <div class="text-left mb-2">
                            <p class="text-muted size-md"><strong>{{'resposta'|translate}}:</strong></p>
                        </div>
                        <textarea class=" input-material mb-0" id="description" style="width: inherit; resize: none"
                                  rows="4" required></textarea>
                    </div>

                    <div class="col-md-12 col-lg-8 offset-lg-2" >
                        <div class="mx-auto">
                            <a href="javascript:void(0)" class="btn btn-block btn-warning mt-4 mb-5 px-5"
                               type="button">{{'enviar'|translate}}</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
