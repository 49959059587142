import {Component, OnInit, AfterViewInit, AfterContentInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../base/base.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Content,
    ContentGQL, CreateContentGQL, CreateContentInput,
    CreateContentOdsInput,
    GetOdsesGQL,
    GriCodesGQL,
    Ods,
    UpdateContentGQL,
    UpdateContentInput
} from '../../../../generated/graphql';
import {OdsSelection} from '../../../model/ods/ods-selection';

@Component({
    selector: 'app-content-detail',
    templateUrl: './content-detail.component.html',
    styleUrls: ['./content-detail.component.scss']
})
export class ContentDetailComponent extends BaseComponent implements AfterContentInit {
    parmId: string;
    content: Content = new Content();
    griCodes: string[] = [];
    odeses: Ods[] = [];

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public loading: NgxSpinnerService,
                public contentGql: ContentGQL,
                public getOdsesGQL: GetOdsesGQL,
                public createContentGQL: CreateContentGQL,
                public updateContentGQL: UpdateContentGQL,
                public griCodesGQL: GriCodesGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    Editor = ClassicEditor;

    ngAfterContentInit(): void {
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url.id)) {
                this.parmId = url.id;
                this.contentGql.watch({id: url.id}).valueChanges.subscribe(({data}) => {
                    this.content = JSON.parse(JSON.stringify(data.content)) as Content;
                    this.odeses = this.content.ods;
                }, error => super.onError(error));
            } else {
                this.getOdsesGQL.watch().valueChanges.subscribe(({data}) => {
                    this.odeses = data.getOdses as Ods[];
                });
            }
            this.griCodesGQL.watch().valueChanges.subscribe(({data}) => {
                this.griCodes = data.griCodes;
            }, error => super.onError(error));
        });
    }

    onSave() {
        const arrValidateFields = [
            {value: this.content.type, text: `Tipo<br>`},
            {value: this.content.gri, text: `Gri<br>`},
            {value: this.content.index, text: `Index<br>`},
            {value: this.content.title, text: `Titulo<br>`},
            {value: this.content.pag, text: `${this.translate.instant('pagReport')}<br>`},
            {value: this.content.content, text: `${this.translate.instant('bodyText')}<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.content.id)) {
            console.log('new');
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    createContentOds() {
        let createContentOdsInput: CreateContentOdsInput[] = [];
        this.odeses.forEach((e) => {
            if (e.isActive) {
                const ods: CreateContentOdsInput = {id: e.id};
                createContentOdsInput.push(ods);
            }
        });
        return createContentOdsInput;
    }

    actionUpdate() {
        const input: UpdateContentInput = {
            id: this.content.id,
            type: this.content.type,
            gri: this.content.gri,
            index: this.content.index,
            title: this.content.title,
            pag: this.content.pag,
            content: this.content.content,
            ods: this.createContentOds(),
            status: this.content.status
        };
        this.updateContentGQL.mutate({input}).subscribe(({data}) => {
            this.router.navigate(['/content']);
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
        }, error => super.onError(error));
    }

    selectedOds(i: number) {
        this.odeses[i].isActive = !this.odeses[i].isActive;
    }

    actionCreate() {
        const input: CreateContentInput = {
            type: this.content.type,
            gri: this.content.gri,
            index: this.content.index,
            title: this.content.title,
            pag: this.content.pag,
            content: this.content.content,
            ods: this.createContentOds(),
        };
        this.createContentGQL.mutate({input}).subscribe(({data}) => {
            this.router.navigate(['/content']);
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningCreateSuccess'), 'success');
        }, error => super.onError(error));
    }

}
