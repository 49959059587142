<div class="d-flex align-items-center ml-4 mt-4">
    <h1 class="title ">FAM |</h1> <span class="subTitle ml-2">Dashboard</span>
</div>

<div class="dashboard">
    <hr>
    <div class="dashboard_top row">
        <div class="col-12 col-md-12 col-lg-7">
            <font class="dashboard_subtitle">{{'dashboard.toptext' | translate}}</font>
        </div>
        <div class="col-12 col-md-12 col-lg-3 offset-lg-1" *ngIf="btnNew">
            <a href="javascript: void(0);" (click)="this.router.navigate(['/register'])"
               class="btn btn-warning btn-home-custom">
              <span class="icon flex-grow-0 mh-100">
                <svg-icon src="assets/svg/plus.svg" class="svg-plus--white--black"></svg-icon>
              </span>
                <span class="separator">|</span>
                <span class="text flex-grow-1 btn-mini-text ">{{'dashboard.novasolicitacao' | translate}}</span>
            </a>
        </div>
    </div>
    <div class="row">

        <div class="col-12 col-xl-4">
            <div class="card-primary">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <p class="card-primary-header-title">{{'dashboard.card1.title' | translate}}
                            <strong> {{'dashboard.card1.etapa' | translate}}</strong></p>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            Gráfico
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-8">
            <div class="card-primary card-primary-beige">
                <div class="card-primary-box box_2">
                    <div class="card-primary-header">
                        <p class="card-primary-header-title">{{'dashboard.card2.title' | translate}}</p>
                        <hr>
                    </div>
                    <div class="card_primary_rolable">
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                Gráfico
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row">

        <div class="col-12 col-xl-6">
            <div class="card-primary">
                <div class="card-primary-box">
                    <div class="card-primary-header">
                        <p class="card-primary-header-title">{{'dashboard.card3.title' | translate}}
                            <strong>{{'dashboard.card3.etapa' | translate}}</strong></p>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            Gráfico
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6">
            <div class="card-primary">
                <div class="card-primary-box">
                    <div class="card-primary-header">

                        <P class="card-primary-header-title">{{'dashboard.card4.title' | translate}}</P>
                        <hr>
                    </div>
                    <div class="card-primary-body">
                        <div class="card-primary-body-graph">
                            Gráfico
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>




