import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../service/user.service";
import {
  Banner,
  CreateBannerGQL, CreateBannerInput, GetBannerGQL, GetBannerQuery,
  GetBannersGQL,
  GetBannersQuery,
  LoginGQL, UpdateBannerGQL, UpdateBannerInput, User,
  UserByKeywordQuery, UserPage
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";

@Component({
  selector: 'app-banner-detail',
  templateUrl: './banner-detail.component.html',
  styleUrls: ['./banner-detail.component.scss']
})
export class BannerDetailComponent extends BaseComponent implements OnInit {

  model: Banner = new Banner();

  file: File = null;
  thumpPath: string | ArrayBuffer;

  cropAspectRatio: any = 4 / 3;
  cropResizeToWidth: any = 400;
  cropResizeToHeight: any = 300;
  cropId: any = 'modalCrop';

  cropCanvasRotation = 0;
  croppedImage: any;
  imageChangedEvent: any;
  croppedFile: any;

  paramId = '';
  bannerQuery: QueryRef<GetBannerQuery>;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public getBannerGQL: GetBannerGQL,
              public createBannerGQL: CreateBannerGQL,
              public updateBannerGQL: UpdateBannerGQL,
              public userService: UserService) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      if (!this.isNullOrUndefined(data.id) && data.id !== 'new'){
        this.paramId = data.id;
        this.getBannerId();
      }
    });
  }

  getBannerId() {
    this.bannerQuery = this.getBannerGQL.watch({id: this.paramId});
    this.bannerQuery.valueChanges.subscribe(({data}) => {
      this.model = Object.assign({}, data.getBanner);
    });
  }

  handleUpload(event) {
    const files = event;
    if (files.length > 0) {
      if (files[0].size > 2000000) {
        super.showMessage(this.translate.instant('profile.edit.warningAttention'),
            this.translate.instant('profile.edit.warningUpload'), 'info');
        return;
      }
      if (files[0].type.indexOf('jpeg') === -1 && files[0].type.indexOf('png') === -1) {
        super.showMessage(this.translate.instant('profile.edit.warningUploadExtensions'), this.translate.instant('profile.edit.warningAttention'),
            'info');
        return;
      }
    }
    const reader = new FileReader();
    reader.onload = (_event) => {
      this.thumpPath = _event.target.result;
    };
    reader.readAsDataURL(event[0]);
    this.file = files[0];
  }

  setCroppedImage(o: any) {
    this.croppedImage = o.thumb;
    this.handleUpload(o.file);
  }

  actionSave() {
    const arrValidateFields = [
      {value: this.model.title, text: `${this.translate.instant('titulo')}<br>`},
      {value: this.model.description, text: `${this.translate.instant('descricao')}<br>`},
      {value: this.model.type, text: 'Local<br>'}
    ];

    if (this.isNullOrUndefined(this.model.id)) {
      // @ts-ignore
      arrValidateFields.push({value: this.file, text: 'Imagem<br>'});
    }

    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
      return;
    }
    if (super.isNullOrUndefined(this.model.id)) {
      this.actionCreated();
    } else {
      this.actionUpdate();
    }
  }

  actionCreated() {
    const a: CreateBannerInput = new CreateBannerInput();
    a.title = this.model.title;
    a.description = this.model.description;
    a.type = this.model.type;
    a.language = this.translate.currentLang === 'pt' ? 'pt-BR' :  'es-ES';
    this.createBannerGQL.mutate({input: a}).subscribe((data) => {
      this.upload(data.data.createBanner.id, this.file, 'banner');
    });
  }

  actionUpdate() {
    const a: UpdateBannerInput = new UpdateBannerInput();
    a.id = this.model.id;
    a.title = this.model.title;
    a.description = this.model.description;
    a.type = this.model.type;
    a.bannerUrl = this.model.bannerUrl;
    a.language = this.translate.currentLang === 'pt' ? 'pt-BR' :  'es-ES';
    this.updateBannerGQL.mutate({input: a}).subscribe(() => {
      if (!this.isNullOrUndefined(this.file)) {
        this.upload(this.model.id, this.file, 'banner');
      } else {
        this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('banneratualizadocomsucesso'), 'success');
        this.router.navigate(['/banner']);
      }
    });
  }

  upload(id: string, file: any, path: string) {
    this.userService.upload(id, file, path).subscribe({
      next: data2 => {
        this.model = new Banner();
        this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('bannercadastradocomsucesso'), 'success');
        this.router.navigate(['/banner']);
      }, error: err => super.onError(err)
    });
  }
}
