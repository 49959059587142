// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    base_url: 'https://euro-indicadores-service.duka.digital/v1',
    client_id: 'a37e38b9-7729-43b3-99c5-c2456d36f8ce',
    tenant_id: '3c815d0f-7a6b-457e-8bfd-d5b195a5425c',
    isLocal: true,
    base_graphql: 'https://euro-indicadores-service.duka.digital/graphql'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
