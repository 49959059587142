<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title">
            {{'destaques'|translate}}
        </h1>
        <div class="d-flex btn-group-custom justify-content-between">
            <a (click)="actionNew()" href="javascript:void(0)"
               class="btn btn-group-custom-block btn-secondary" type="button">
                <svg-icon class="svg-primary mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'novodestaque'|translate}}</span>
            </a>
        </div>
    </div>
    <div class="container">
        <div class="highlight_row" *ngFor="let h of highlights">
            <div class="top green" [ngStyle]="{'color': h.color}">
                <img class="highlight_img" [src]="h.icon" alt="icon">
                <b>{{h.name}}</b>
            </div>
            <div class="row">
                <div class="col-12 col-md-3" *ngFor="let i of h.itens">
                    <div class="highlight_card" [ngStyle]="{'background-color': h.color}">
                        <b>{{i.title}}</b>
                        <p>{{i.description}}</p>
                        <div class="bottom">
                            <a href="javascript:void(0)" (click)="actionEdit(i,h.id)">
                                <svg-icon class="mr-5" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <div class="divider"></div>
                            <a href="javascript:void(0)" (click)="actionDelete(i.id)">
                                <svg-icon class="ml-5" src="assets/svg/closer.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- modalHighlights-->
    <div class="modal-custom modal fade" id="modalHighlights" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal_header">
                    <h3 class="header-title">
                        {{'destaque'|translate}}
                    </h3>
                    <a href="javascript:void('');"
                       class="btn "
                       type="button" (click)="closeModal('modalHighlights')">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>
                </div>
                <div class="modal-body modal-body--small">
                    <div class="row my-3">
                        <div class="col-4" *ngFor="let h of highlights">
                            <div (click)="highType = h.id" style="gap: 10px"
                                 class="cursor-pointer d-flex flex-column justify-content-center align-items-center">
                                <img style="height: 32px" [src]="h.icon" alt="icon">
                                <p [ngClass]="{'active':highType== h.id}"
                                   [ngStyle]="{'color': highType== h.id?h.color:null}"
                                   class="text-highlight">{{h.name}}</p>
                            </div>
                        </div>
                        <!--                        <div class="col-4">-->
                        <!--                            <div (click)="highType='society'"-->
                        <!--                                 class="cursor-pointer d-flex flex-column justify-content-center align-items-center">-->
                        <!--                                <svg-icon [ngClass]="{'active':highType=='society'}" class="svg_highlight secondary"-->
                        <!--                                          id="svg2" src="assets/svg/heartgray.svg"></svg-icon>-->
                        <!--                                <p [ngClass]="{'active':highType=='society'}"-->
                        <!--                                   class="text-highlight secondary">{{'sociedade'|translate}}</p>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-4">-->
                        <!--                            <div (click)="highType='govern'"-->
                        <!--                                 class="cursor-pointer d-flex flex-column justify-content-center align-items-center">-->
                        <!--                                <svg-icon [ngClass]="{'active':highType=='govern'}" class="svg_highlight tertiary"-->
                        <!--                                          id="svg3" src="assets/svg/geargray.svg"></svg-icon>-->
                        <!--                                <p [ngClass]="{'active':highType=='govern'}"-->
                        <!--                                   class="text-highlight tertiary">{{'governança'|translate}}</p>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-12 mt-4">
                            <div class="input-material">
                                <input id="title" [(ngModel)]="highlightItem.title" class="form-control" type="text"
                                       required maxlength="250"/>
                                <label for="title">{{'titulo'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="input-material">
                                <input id="desc" [(ngModel)]="highlightItem.description" class="form-control" type="text"
                                       required maxlength="250"/>
                                <label for="desc">{{'descricao'|translate}}</label>
                            </div>
                        </div>

                    </div>
                    <button (click)="onSave()" class="btn btn-block btn-primary mt-5">{{'salvar'|translate}}</button>
                </div>

            </div>
        </div>
    </div>


</div>

