import {Component, OnDestroy, OnInit, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    CreateHighlightGQL, CreateHighlightItemHighlightInput, CreateHighlightItemInput, Highlight, HighlightItem,
    HighlightsGQL,
    HighlightsQuery, RemoveHighlightGQL, UpdateHighlightGQL, UpdateHighlightItemInput
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';

declare var $: any;

@Component({
    selector: 'app-highlights',
    templateUrl: './highlights.component.html',
    styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    highType: string;
    highlightsQuery: QueryRef<HighlightsQuery>;
    highlights: Highlight[] = [];
    highlightItem: HighlightItem = new HighlightItem();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private highlightsGQL: HighlightsGQL,
                private removeHighlightGQL: RemoveHighlightGQL,
                private createHighlightGQL: CreateHighlightGQL,
                private updateHighlightGQL: UpdateHighlightGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngAfterContentInit(): void {
        this.loading.show();
        this.highlightsQuery = this.highlightsGQL.watch();
        this.highlightsQuery.valueChanges.subscribe(({data}) => {
            this.highlights = data.highlights as Highlight[];
            this.loading.hide();
        }, error => super.onError(error));
    }


    ngOnDestroy(): void {
        super.destroyModal('modalHighlights');
    }

    onFilter() {
        this.loading.show();
        this.highlightsQuery.refetch().finally(() => {
            this.loading.hide();
        });
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.removeHighlightGQL.mutate({id}).subscribe((result) => {
                this.onFilter();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningDeleteSuccess'), 'success');
            });
        });
    }

    actionEdit(highlightItem: HighlightItem, type: string) {
        this.highlightItem = Object.assign({}, highlightItem);
        this.highType = type;
        this.showModal('modalHighlights');
    }

    actionNew() {
        this.highlightItem = new HighlightItem();
        this.highType = '';
        this.showModal('modalHighlights');
    }

    onSave() {
        const arrValidateFields = [
            {value: this.highlightItem.title, text: `Título<br>`},
            {value: this.highlightItem.description, text: `Descrição<br>`},
            {value: this.highType, text: `Tipo<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.highlightItem.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        const highlightInput: CreateHighlightItemHighlightInput = {
            id: this.highType,
        };
        const input: CreateHighlightItemInput = {
            title: this.highlightItem.title,
            description: this.highlightItem.description,
            highlight: highlightInput
        };
        this.createHighlightGQL.mutate({input}).subscribe(({data}) => {
            this.closeModal('modalHighlights');
            this.onFilter();
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningCreateSuccess'), 'success');
        }, error => super.onError(error));
    }

    actionUpdate() {
        const highlightInput: CreateHighlightItemHighlightInput = {
            id: this.highType,
        };
        const input: UpdateHighlightItemInput = {
            id: this.highlightItem.id,
            title: this.highlightItem.title,
            description: this.highlightItem.description,
            highlight: highlightInput
        };
        this.updateHighlightGQL.mutate({input}).subscribe(({data}) => {
            this.closeModal('modalHighlights');
            this.onFilter();
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
        }, error => super.onError(error));
    }
}
