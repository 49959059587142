<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title">
            {{'metaslongoprazo'|translate}}
        </h1>
        <div class="d-flex flex-wrap btn-group-custom justify-content-between " style="row-gap: 15px">
            <a (click)="showModal('modalGroupList')" href="javascript:void(0)"
               class="btn btn-grupo btn-group-custom btn-sextenary mr-2" type="button">
                <svg-icon class="svg-white mr-2" src="assets/svg/gear.svg"></svg-icon>
                <span>{{'grupo'|translate}}</span>
            </a>
            <a [routerLink]="['/long-term/new']" href="javascript:void(0)"
               class="btn btn-group-custom btn-secondary" type="button">
                <svg-icon class="svg-primary mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'novoconteudo'|translate}}</span>
            </a>
        </div>
    </div>

    <div class="row justify-content-between align-items-center">
        <!-- KEYWORD -->
        <div class="col-md-3">
            <div class="input-material">
                <input id="tittle" class="form-control" type="text" [(ngModel)]="modelFilter.keyword"
                       required maxlength="150"/>
                <label for="Tittle">{{'busca'|translate}}</label>
            </div>
        </div>
        <!-- PERIOD -->
        <div class="col-md-3">
            <div class="input-material">
                <input id="periodFilter" class="form-control picker-period" type="text"
                       required maxlength="300"/>
                <!--picker-period-->
                <label for="periodFilter" class="mb-0 mr-3">{{'Periodo'|translate}}</label>
            </div>
        </div>
        <!-- Grupo -->
        <div class="col-md-3">
            <div class="input-material">
                <select id="country" class="form-control" name="type" [(ngModel)]="modelFilter.group">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option *ngFor="let x of modelGroup" [ngValue]="x.id">{{x.name}}</option>
                </select>
                <label for="country" class="mb-0 mr-3"> {{'Grupo'|translate}}</label>
            </div>
        </div>
        <!-- Status -->
        <div class="col-md-3">
            <div class="input-material">
                <select id="unity" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [ngValue]="true">Ativo</option>
                    <option [ngValue]="false">Inativo</option>
                </select>
                <label for="unity" class="mb-0 mr-3">{{'Status'|translate}}</label>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end col-12  p-0 mb-3">
        <a href="javascript:void(0)"
           class="btn btn-primary col-12 col-md-2"
           type="button" (click)="modelFilter.page = 1; onFilter();">
            <span class="ml-1">{{'buscar'|translate}}</span>
        </a>
    </div>

    <!--GRID-->

    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">Grupo</th>
                <th scope="col">Título</th>
                <th scope="col">Data Criação</th>
                <th scope="col">Autor</th>
                <th scope="col">Status</th>
                <th scope="col" class="w-10">&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="model?.results?.length === 0">
                <td colspan="7"><p class="py-5 text-center">Nenhum registro encontrado.</p></td>
            </tr>
            <tr *ngFor="let x of model?.results |
                    paginate: {itemsPerPage: model.pageSize,
                    currentPage: model.currentPage,
                    totalItems: model.totalCount}">
                <td>{{x?.meta?.name}}</td>
                <td>{{x?.title}}</td>
                <td>{{x?.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td>{{x?.user?.name}}</td>
                <td>
                    <!--{{x?.status ? 'Ativo' : 'Inativo'}}-->
                   <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="accept-terms2"
                               [checked]="x?.status"
                               [disabled]="true"
                        >
                        <label class="custom-control-label" for="accept-terms2"></label>
                    </div>
                </td>
                <td>
                    <div class="d-flex">
                        <button [routerLink]="'/long-term/' + x?.id">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDeleteMetaItem(x?.id)">
                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>

</div>


<div class="modal-custom modal fade" id="modalGroupList" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal_header">
                <h3 class="header-title">
                    {{'grupo'|translate}}
                </h3>
                <a (click)="closeModal('modalGroupList')" href="javascript:void('');"
                   class="btn "
                   type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <button class="btn btn-block btn-primary mt-3" (click)="actionNewMeta()">{{'novo'|translate}}</button>
                <div class="row my-3">
                    <div class="col-12">
                        <table class="table">
                            <tr *ngFor="let x of modelGroup">
                                <td>{{x.name}}</td>
                                <td>{{x.color}}</td>
                                <td class="w-10">
                                    <div class="d-flex">
                                        <button (click)="actionEdit(x)">
                                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                                        </button>
                                        <button (click)="actionDelete(x?.id)">
                                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalGroup" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal_header">
                <h3 class="header-title">
                    {{'novogrupo'|translate}}
                </h3>
                <a (click)="closeModal('modalGroup')" href="javascript:void('');"
                   class="btn "
                   type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row my-3">
                    <div class="col-12">
                        <div class="input-material">
                            <input id="reUser" class="form-control" type="text" [(ngModel)]="modelMeta.name"
                                   required maxlength="250"/>
                            <label for="reUser">{{'nomedogrupo'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="new-gri col-12 my-3">
                            <div class="col-md-9 d-flex flex-column justify-content-center">
                                <b class="mr-3 justify-content-center align-items-center">{{'definaumacor'|translate}}</b>
                                <p class="mr-3 justify-content-center align-items-center">{{modelMeta.color}}</p>
                            </div>
                            <div class="col-3">
                                <input class="cursor-pointer colorPicker" [(colorPicker)]="color"
                                       [style.background]="color" (colorPickerChange)="handlerColor($event)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-block btn-primary mt-3" (click)="actionSave();">{{'salvar'|translate}}</button>
            </div>
        </div>
    </div>
</div>
