import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {CurrentUserGQL} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private currentUserGQL: CurrentUserGQL,
                public userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        // console.log('aqui main');
        // console.log(this.userService.getStatusAuthenticated());
        if (this.userService.getStatusAuthenticated()) {
            this.getCurrentUser();
        } else {
            localStorage.clear();
            this.userService.isAuthenticated = false;
            this.router.navigate(['/logout']);
        }
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function () {
            $(this).appendTo('body');
        });
        /*** MULTIPLE MODAL BOOTSTRAP ***/
        $(function () {
            $(document).on('show.bs.modal', '.modal', function () {
                var maxZ = parseInt($('.modal-backdrop').css('z-index')) || 1040;
                $('.modal:visible').each(function () {
                    maxZ = Math.max(parseInt($(this).css('z-index')), maxZ);
                });
                $('.modal-backdrop').css('z-index', maxZ);
                $(this).css("z-index", maxZ + 1);
                $('.modal-dialog', this).css("z-index", maxZ + 2);
            });
            $(document).on('hidden.bs.modal', '.modal', function () {
                if ($('.modal:visible').length) {
                    $(document.body).addClass('modal-open');
                    var maxZ = 1040;
                    $('.modal:visible').each(function () {
                        maxZ = Math.max(parseInt($(this).css('z-index')), maxZ);
                    });
                    $('.modal-backdrop').css('z-index', maxZ - 1);
                }
            });
        });
        /*** / MULTIPLE MODAL BOOTSTRAP ***/
    }

    getCurrentUser() {
        this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
            const a: any = data;
            this.userService.updateUser(a.currentUser);
        });
    }

}
