<!-- Modal CROP -->
<div class="modal fade modal-crop" [id]="cropId" tabindex="-1" role="dialog" aria-labelledby="moreLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
            <div class="modal-header">
                <div class="d-flex align-items-center">
                    <svg-icon class="svg-icon-primary icon-crop" src="assets/svg/addimage.svg"></svg-icon>
                    <h3 class="modal-title ml-3">
                        <strong class="text-primary">{{'enviarimagem'|translate}}</strong>
                    </h3>
                </div>

                <a href="javascript:void('');" data-dismiss="modal">
                    <svg-icon class="icon-crop" src="assets/svg/close-circle.svg"></svg-icon>
                </a>
                <!--<a class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/close.svg"></svg-icon>
                </a>-->
            </div>
            <div class="modal-body flex-column px-0 pb-0">
                <div class="d-flex ml-3 align-items-center" *ngIf="!isNullOrUndefined(imageChangedEvent)">
                    <div class="btn-custom-crop" (click)="onReset()">
                        <svg-icon class="svg-icon-primary icon-crop" src="assets/svg/addimage.svg"></svg-icon>
                        <a href="javascript:void('');" >{{'novaimagem'|translate}}</a>
                    </div>
                    <div class="btn-custom-crop" (click)="onImageRotate()">
                        <svg-icon  class="svg-icon-primary icon-crop" src="assets/svg/rotate.svg"></svg-icon>
                        <a href="javascript:void('');" >{{'rotacionar'|translate}}</a>
                    </div>
                    <div class="btn-custom-crop" (click)="onImageSave()">
                        <svg-icon  class="svg-icon-primary icon-crop2" src="assets/svg/disquete.svg"></svg-icon>
                        <a href="javascript:void('');"  style="margin-top: -10px">{{'salvar'|translate}}</a>
                    </div>
                </div>
                <div class="px-4">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <div class="description__photo mt-5" *ngIf="isNullOrUndefined(imageChangedEvent)">
                            <div class="border-0 w-100">
                                <div class="file-field text-center mb-4">
                                    <img src="/assets/images/prevent-upload.png" alt="Upload" class="img-fluid"/>
                                </div>
                            </div>
                            <input class="m-3" type="file" class="description__photo--file" name="profile_photo" onclick="this.value = null"
                                   (change)="fileChangeEvent($event)" #newItem/>
                        </div>
                        <image-cropper
                                [format]="'png'"
                                [imageQuality]="40"
                                [imageChangedEvent]="imageChangedEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="cropAspectRatio"
                                (imageCropped)="imageCropped($event)"
                                (imageLoaded)="imageLoaded()"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()"
                                [canvasRotation]="cropCanvasRotation"
                                [resizeToWidth]="cropResizeToWidth"
                                [resizeToHeight]="cropResizeToHeight"
                        ></image-cropper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
