import {Component, OnInit, AfterViewInit, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {ContentPage, RemoveContentGQL, SearchContentGQL, SearchContentInput, SearchContentQuery} from '../../../generated/graphql';
import moment from 'moment';
import {QueryRef} from 'apollo-angular';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent extends BaseComponent implements AfterContentInit {
    filter: SearchContentInput = new SearchContentInput();
    listSearch: ContentPage = new ContentPage();
    searchContentQuery: QueryRef<SearchContentQuery>;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private searchContentGQL: SearchContentGQL,
                private removeContentGQL: RemoveContentGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngAfterContentInit(): void {
        this.filter.start = moment().add(-30, 'days').format();
        this.filter.end = moment().format();
        this.loading.show();
        this.searchContentQuery = this.searchContentGQL.watch({data: this.filter});
        this.searchContentQuery.valueChanges.subscribe(({data}) => {
            this.listSearch = data.searchContent as ContentPage;
            this.loading.hide();
        }, error => super.onError(error));
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    onFilter() {
        this.loading.show();
        this.searchContentQuery.refetch({data: this.filter}).finally(() => {
            this.loading.hide();
        });
    }

    selectedDate(event: any) {
        this.filter.start = event.start.format();
        this.filter.end = event.end.format();
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.removeContentGQL.mutate({id}).subscribe((result) => {
                this.onFilter();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningDeleteSuccess'), 'success');
            });
        });
    }
}
