import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';

declare var $: any;

export class BaseComponent {
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    typeContent = ['material', 'gri'];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    setCulture(culture: string) {
        localStorage.setItem('culture', culture);
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
            this.setCulture('pt-BR');
        } else if (language === 'en') {
            language = 'en';
            this.setCulture('en-US');
        } else if (language === 'es') {
            language = 'es';
            this.setCulture('es-ES');
        }
        this.translate.use(language);
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    destroyModal(idModal) {
        const elModalUser = $('#' + idModal);
        if (elModalUser[0]) {
            elModalUser.remove();
        }
        const backdrop = $('.modal-backdrop.show');
        if (backdrop[0]) {
            backdrop.remove();
        } else {
            $('body').removeClass('modal-open');
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0 || value.toString().trim() === '';
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    getLanguage() {
        return localStorage.getItem('language');
    }

    onError(error) {
        this.loading.hide();
        if (error.status === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    setDatePicker(id: string, callback, date = 30) {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, callback);
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    onLogout() {
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningExit'), () => {
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;
        });
    }

    getStatusNameByKey(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant('statusNames.' + s);
        }
    }

    getMangerTypeByKey(k: string) {
        if (!this.isNullOrUndefined(k)) {
            return this.translate.instant('managersName.' + k);
        }
    }

}
