<div class="body">
    <div class="header border-0">
        <div class="d-flex">
            <a href="javascript:void('');" [routerLink]="'/banner'">
                <svg-icon class="mr-5" src="assets/svg/back.svg"></svg-icon>
            </a>
            <h1 class="header-title">
                {{'banner'|translate}}
            </h1>
        </div>


    </div>
    <div class="container banner_container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                    <!--<div class="d-flex">
                        <div class="card_beige beige-header d-flex justify-content-between align-items-center">
                            <b>Status Ativo</b>
                            <label class="switch  cursor-default">
                                <input type="checkbox" name="checkbox">
                                <span class="slider round cursor-default"></span>
                            </label>
                        </div>
                    </div>-->
                    <form class="form-group p-10">
                        <div class="input-material my-3">
                            <input class="form-control " type="text" [(ngModel)]="model.title" name="title"
                                   required maxlength="150" />
                            <label>{{'titulo'|translate}}</label>
                        </div>
                        <div class="input-material my-3">
                            <input class="form-control " type="text" [(ngModel)]="model.description" name="description"
                                   required maxlength="150"/>
                            <label>{{'descricao'|translate}}</label>
                        </div>
                        <!--<div class="btnlink">
                            <b>Botão com Link</b>
                        </div>
                        <div class="input-material my-3">
                            <input class="form-control" type="text"
                                   required maxlength="150"/>
                            <label for="">  {{'Link'|translate}}</label>
                        </div>
                        <div class="input-material my-3">
                            <input class="form-control" type="text"
                                   required maxlength="150"/>
                            <label for="">  {{'Título do botão'|translate}}</label>
                        </div>-->

                        <div class=" select_row mt-3">
                            <b>Local</b>
                            <div class="form-check">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    {{'banners'|translate}}
                                </label>
                                <input class="form-check-input select_radio" type="radio" name="flexRadioDefault"
                                        [(ngModel)]="model.type" [value]="'banner'"
                                        [checked]="model.type === 'banner'"
                                       id="flexRadioDefault1">
                            </div>
                            <div class="form-check select_btn">
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Background
                                </label>
                                <input class="form-check-input select_radio" type="radio" name="flexRadioDefault"
                                        [(ngModel)]="model.type" [value]="'background'"
                                        [checked]="model.type === 'background'"
                                       id="flexRadioDefault2" checked>
                            </div>
                        </div>

                    </form>
                </div>


            </div>
            <div class="col-12 col-md-6">
                <div class="card_beige">
                    <div class="card_beige_header">
                        <b>{{'imagem'|translate}}</b>
                    </div>
                    <div class="card_beige_content cursor-pointer" (click)="showModal('modalCropBanner')">
                        <!--<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="!isNullOrUndefined(model.bannerUrl)">
                            <img src="{{model.bannerUrl}}" alt="" />
                        </div>-->
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <svg-icon *ngIf="isNullOrUndefined(croppedImage) && isNullOrUndefined(model.bannerUrl)" src="assets/svg/image.svg"></svg-icon>
                            <p *ngIf="isNullOrUndefined(croppedImage) && isNullOrUndefined(model.bannerUrl)">{{'facauploaddoarquivo'|translate}}</p>
                            <img *ngIf="!isNullOrUndefined(croppedImage) || !isNullOrUndefined(model.bannerUrl)"
                                 class="img_thumb" src="{{!croppedImage ? model.bannerUrl : croppedImage}}"
                                 alt="">
                        </div>
                    </div>
                    <!--<div class="card_beige_footer mt-4">
                        <div class="input-material col-12">
                            <input id="period" class="form-control" type="text" required/>
                            <label for="period">Titulo da Imagem</label>
                        </div>
                    </div>-->
                </div>

            </div>
        </div>
        <div class="row mt-5">
            <div class="col-6">
                <div class="col-12 p-0">
                    <div class="col-12 p-0 mb-3">
                        <a href="javascript:void(0)"
                           class="btn btn-outline-primary btn-block"
                           type="button">
                            <span class="ml-1">{{'cancelar'|translate}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 p-0 mb-3">
                    <a href="javascript:void(0)" (click)="actionSave()"
                       class="btn btn-primary btn-block"
                       type="button">
                        <span class="ml-1">{{'salvar'|translate}}</span>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>


<app-crop-image [cropId]="'modalCropBanner'"
                [cropAspectRatio]="18/12"
                [cropResizeToWidth]="1280"
                [cropResizeToHeight]="430"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
