import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-card-profile',
    template: `
        <div class="card-profile">
            <img class="card-profile-image" [ngClass]="borderImage"
                 src="{{this.isNullOrUndefined(image) ? 'assets/images/profile.jpg' : image}}" alt="profile">
            <div class="d-flex flex-column">
                <span class="card-profile-title">{{name}}</span>
                <span>{{unity}}</span>
            </div>
        </div>`,
    styleUrls: ['./card-profile.component.scss']
})
export class CardProfileComponent extends BaseComponent implements OnInit {
    @Input()
    borderImage: string | string[] | Set<string> | {
        [klass: string]: any;
    };
    @Input()
    image: string;
    @Input()
    name: string;
    @Input()
    unity: string;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
    }

}
