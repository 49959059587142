<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title">
            {{'banners'|translate}}
        </h1>
        <div class="d-flex btn-group-custom justify-content-between">
            <a [routerLink]="['/banner-detail/new']" href="javascript:void(0)"
               class="btn btn-group-custom btn-secondary" type="button">
                <svg-icon class="svg-primary mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'novobanner'|translate}}</span>
            </a>
        </div>
    </div>

    <div class="row justify-content-between">
        <div class="col-md-4">
            <div class="input-material">
                <input id="tittle" class="form-control" type="text"
                       required maxlength="150"/>
                <label for="Tittle">  {{'titulo'|translate}}</label>
            </div>
        </div>
        <!--<div class="col-12 col-md-2">
            <div class="input-material">
                <select id="country" class="form-control" name="type">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option>Teste</option>
                </select>
                <label for="country" class="mb-0 mr-3"> {{'Tipo'|translate}}</label>
            </div>
        </div>
        <div class="col-12 col-md-2 ">
            <div class="input-material">
                <select id="profile" class="form-control" name="type">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option [ngValue]="'user'">user</option>
                    <option [ngValue]="'admin'">admin</option>
                </select>
                <label for="profile" class="mb-0 mr-3">{{'Status'|translate}}</label>
            </div>
        </div>
        <div class="col-12 col-md-2">
            <div class="input-material">
                <select id="unity" class="form-control" name="type">
                    <option selected
                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                    <option>Teste</option>

                </select>
                <label for="unity" class="mb-0 mr-3">{{'Periodo'|translate}}</label>
            </div>
        </div>-->
        <div class="col-md-2 buscar-banner btn-group-custom">
            <div class="btn-group-custom ">
                <a href="javascript:void(0)" class="btn  btn-primary btn-block" type="button">
                    <span class="ml-1">{{'buscar'|translate}}</span>
                </a>
            </div>

        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'idioma'|translate}}</th>
                <th scope="col">{{'local'|translate}}</th>
                <th scope="col">{{'titulo'|translate}}</th>
                <th scope="col">{{'descricao'|translate}}</th>
                <th scope="col">{{'Imagem'|translate}}</th>
                <th scope="col" class="w-10"></th>
            </tr>
            </thead>
            <tbody>
                <tr *ngIf="model.length === 0">
                    <td colspan="7"><p class="py-5 text-center">Nenhum registro encontrado.</p></td>
                </tr>
                <tr *ngFor="let x of model">
                    <td>{{x.language}}</td>
                    <td>{{x.type}}</td>
                    <td>{{x.title}}</td>
                    <td>{{x.description}}</td>
                    <td>
                        <div class="image-thumb image-thumb-xs">
                            <img src="{{x.bannerUrl}}" alt="" />
                        </div>
                    </td>
                    <td>
                        <a href="javascript:void('');" [routerLink]="'/banner-detail/' + x.id">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('');" (click)="actionDelete(x.id);">
                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

