import {Router} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DatePickerCustomComponent} from './date-picker-custom.component';
import {HttpLoaderFactory} from '../../../app.module';
import {BaseComponent} from '../../../base/base.component';

@NgModule({
    declarations: [
        DatePickerCustomComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forRoot(),
    ],
    providers: [
        {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        TranslateService
    ],
    exports: [
        CommonModule,
        TranslateModule,
        DatePickerCustomComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DatePickerCustomModule extends BaseComponent {
    constructor(
        public router: Router,
        public translate: TranslateService,
        public loading: NgxSpinnerService) {
        super(router, loading, translate);
        translate.use(super.getLanguage());
    }
}
