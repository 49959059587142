import {Component, OnInit, AfterContentInit} from '@angular/core';
import {
    ContentPage, GriPage,
    RemoveContentGQL, RemoveGriGQL,
    SearchContentInput,
    SearchContentQuery, SearchGriGQL, SearchGriInput, SearchGriQuery,
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {BaseComponent} from '../../base/base.component';

@Component({
    selector: 'app-gri',
    templateUrl: './gri.component.html',
    styleUrls: ['./gri.component.scss']
})
export class GriComponent extends BaseComponent implements AfterContentInit {
    filter: SearchGriInput = new SearchGriInput();
    listSearch: GriPage = new GriPage();
    searchGriQuery: QueryRef<SearchGriQuery>;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private searchGriGQL: SearchGriGQL,
                private removeGriGQL: RemoveGriGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngAfterContentInit(): void {
        this.loading.show();
        this.searchGriQuery = this.searchGriGQL.watch({input: this.filter});
        this.searchGriQuery.valueChanges.subscribe(({data}) => {
            this.listSearch = data.searchGri as GriPage;
            this.loading.hide();
        }, error => super.onError(error));
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    onFilter() {
        this.loading.show();
        this.searchGriQuery.refetch({input: this.filter}).finally(() => {
            this.loading.hide();
        });
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.removeGriGQL.mutate({id}).subscribe((result) => {
                this.onFilter();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningDeleteSuccess'), 'success');
            });
        });
    }
}
