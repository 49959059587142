<div class="gri body">
    <div class="header border-0 mb-0">
        <div class="d-flex">
            <svg-icon routerLink="/gri" class="mr-5 cursor-pointer" src="assets/svg/back.svg"></svg-icon>
            <h1 class="header-title">
                {{'GRI'|translate}}
            </h1>
        </div>
    </div>
    <div class="row align-items-center my-2" style="row-gap: 20px">
        <div class="col-md-4">
            <div class="input-material mb-0">
                <input id="title" class="form-control" type="text"
                       [(ngModel)]="gri.title"
                       required maxlength="150"/>
                <label for="title">  {{'Título'|translate}}</label>
            </div>
        </div>
        <div class="col-md-5">
            <div class="input-material mb-0">
                <input id="description" class="form-control" type="text"
                       [(ngModel)]="gri.description"
                       required maxlength="150"/>
                <label for="description">  {{'descricao'|translate}}</label>
            </div>
        </div>
        <div class="col-md">
            <div class="gri-card">
                <div class="row px-3 pt-3 pb-2">
                    <div class="col">
                        <div class="d-flex flex-column justify-content-center">
                            <b>{{'definaumacor'|translate}}</b>
                            <p class="mb-0">{{gri.color}}</p>
                        </div>

                    </div>
                    <div class="col-auto">
                        <label>
                            <input class="colorPicker" [(colorPicker)]="gri.color"
                                   [style.background]="gri.color"/>
                        </label>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="divider">&nbsp;</div>
    <div class="gri-card p-3 mt-3">
        <div class="pt-3 d-flex flex-wrap wrap justify-content-between align-items-center">
            <h5 class="text-primary" [innerHTML]="'definircategorias'|translate"></h5>
            <a href="javascript:void(0)"
               class="btn btn-primary col-12 col-md-2"
               type="button">
                <span class="ml-1" (click)="createGroup()">{{'criargrupo'|translate}}</span>
            </a>

        </div>
        <div class="row mt-4">
            <div class="col-md-6 col-xxl-4" *ngFor="let g of gri.groups ,let i = index">
                <div class="table-responsive">
                    <table class="table gri_table">
                        <thead>
                        <tr>
                            <th>
                                <div class="gri_table_title d-flex align-items-center">
                                    <span class="flex-grow-1">{{g.name}}</span>
                                    <div class="d-flex" style="gap: 10px">
                                        <a href="javascript:void(0)" (click)="editGroup(g,i)">
                                            <svg-icon src="assets/svg/edit-table.svg"></svg-icon>
                                        </a>
                                        <a href="javascript:void(0)" (click)="removeGroup(i)">
                                            <svg-icon src="assets/svg/closer.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let c of g.content">
                            <td>
                                <p class="gri_table_text d-flex" style="gap: 20px">
                                    <span>{{c.gri}}</span>
                                    {{c.title}}
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-5">
        <a href="javascript:void(0)"
           (click)="onSave()"
           class="btn btn-primary col-12 col-md-2"
           type="button">
            <span class="ml-1">{{'salvar'|translate}}</span>
        </a>
    </div>

</div>

<div class="modal-custom modal fade" id="modalGri" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal_header">
                <h3 class="header-title">
                    {{(isNullOrUndefined(griGroup.id) ? 'novogrupo' : 'editgrupo')|translate}}
                </h3>
                <a (click)="closeModal('modalGri')" href="javascript:void(0);"
                   class="btn" type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row my-3">
                    <div class="col-12">
                        <div class="input-material">
                            <input id="reUser" [(ngModel)]="griGroup.name" class="form-control" type="text"/>
                            <label for="reUser">{{'nomecategoria'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <span class="modalSub">{{'busquecodigoGRI'|translate}}</span>
                        <div class="d-flex align-items-center mt-3" style="gap: 10px">
                            <div class="input-material mb-0 flex-grow-1">
                                <input id="searchCode" appOnlyNumber [(ngModel)]="code" (keyup)="getContent()"
                                       class="form-control"
                                       type="text"
                                       required maxlength="15"/>
                                <label for="searchCode">
                                    {{'buscarGRI'|translate}}
                                </label>

                                <div class="autocomplete-panel" *ngIf="code?.length > 0">
                                    <div *ngIf="content?.length === 0" class="theme_row text-center">
                                        <span>Nenhum item encontrado.</span>
                                    </div>
                                    <div (click)="addContent(c)" class="theme_row is-cursor"
                                         *ngFor="let c of content">
                                        <b>{{c.gri}}</b>
                                        <span>{{c.title}}</span>
                                        <div></div>
                                    </div>
                                </div>

                            </div>
                            <!--<a href="javascript:void(0)" (click)="getContent()" class="btn btn-primary">
                                <svg-icon src="assets/svg/look.svg"></svg-icon>
                            </a>-->
                        </div>
                    </div>
                </div>
                <div class="gri_text d-flex border-bottom py-2" style="gap: 20px;"
                     *ngFor="let g of griGroup.content,let i = index">
                    <span>{{g.gri}}</span>
                    <p class="flex-grow-1 mb-0">{{g.title}}</p>
                    <a (click)="removeContent(i)">
                        <svg-icon src="assets/svg/closer.svg"></svg-icon>
                    </a>
                </div>
                <!--<ng-container *ngFor="let c of content">
                    <div (click)="addContent(c)" class="gri_text d-flex border-bottom py-2"
                         style="gap: 20px;min-height: 39px">
                        <span>{{c.gri}}</span>
                        <p class="flex-grow-1 mb-0">{{c.title}}</p>
                    </div>
                </ng-container>-->
                <button (click)="saveGroup()" class="btn btn-block btn-primary mt-3">{{'salvar'|translate}}</button>
            </div>

        </div>
    </div>
</div>
