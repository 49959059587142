import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {
    User,
    CreateUserGQL,
    UpdateUserGQL,
    RemoveUserGQL,
    CreateUserInput, UpdateUserInput, UserByKeywordQuery, UserByKeywordGQL, UserPage
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {
    users: UserPage = new UserPage();
    usersQuery: QueryRef<UserByKeywordQuery>;
    model: User = new User();
    filter: any = {page: 1, keyword: ''};

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private UserByKeywordGQL: UserByKeywordGQL,
                private createUserGQL: CreateUserGQL,
                private updateUserGQL: UpdateUserGQL,
                private removeUserGQL: RemoveUserGQL
    ) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.setDatePicker('picker-period', (start, end, label) => {
            console.log(start.utcOffset(0, true).format());
            console.log(end.utcOffset(0, true).format());
        });
        this.getUsers();
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    getUsers() {
        this.usersQuery = this.UserByKeywordGQL.watch(this.filter);
        this.usersQuery.valueChanges.subscribe(({data}) => {
            this.users = data.userByKeyword as UserPage;
        });
    }

    onFilter() {
        this.usersQuery.refetch(this.filter);
    }

    actionNew() {
        this.model = new User();
        this.showModal('modalUser');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `${this.translate.instant('nome')}<br>`},
            {value: this.model.email, text: `${this.translate.instant('email')}<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }

        if (!this.isEMailValid(this.model.email)) {
            return;
        }

        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    actionEdit(user: User) {
        this.model = Object.assign({}, user);
        this.showModal('modalUser');
    }

    actionCreatedUser() {
        const data = new CreateUserInput();
        data.name = this.model.name;
        data.email = this.model.email;
        this.createUserGQL.mutate({input: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch();
            this.closeModal('modalUser');
        });
    }

    actionUpdateUser() {
        const data = new UpdateUserInput();
        data.id = this.model.id;
        data.name = this.model.name;
        data.email = this.model.email;
        console.log(this.model);
        console.log(data);
        this.updateUserGQL.mutate({input: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch(this.filter);
            this.closeModal('modalUser');
        });
    }

    actionDelete(userId) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningUserDelete'), () => {
            this.removeUserGQL.mutate({id: userId}).subscribe((result) => {
                this.usersQuery.refetch(this.filter);
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUserDeleteSucess'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

}
