<div class="body">
    <div class="header border-0 flex-wrap">
        <h1 class="header-title">
            {{'conteudos'|translate}}
        </h1>
        <div class="d-flex btn-group-custom justify-content-between">
            <a [routerLink]="['/content-detail/new']" href="javascript:void(0)"
               class="btn btn-group-custom btn-secondary" type="button">
                <svg-icon class="svg-primary mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'novoconteudo'|translate}}</span>
            </a>
        </div>
    </div>


    <div class="row justify-content-between align-items-center">
        <div class="col-md-5">
            <div class="input-material">
                <input id="title" [(ngModel)]="filter.keyword" class="form-control" type="text"
                       required maxlength="150"/>
                <label for="title">{{'titulo'|translate}}</label>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-material">
                <select id="type" [(ngModel)]="filter.type" class="form-control text-capitalize" name="type">
                    <option selected
                            [ngValue]="undefined">Todos
                    </option>
                    <option *ngFor="let t of typeContent">{{t}}</option>
                </select>
                <label for="type" class="mb-0 mr-3">Tipo</label>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-material">
                <app-date-picker-custom
                        (selectDate)="selectedDate($event)"
                        [class]="'form-control'"
                        [id]="'date'"
                        [start]="this.filter.start"
                        [end]="this.filter.end"
                ></app-date-picker-custom>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-material">
                <select id="unity" [(ngModel)]="filter.status" class="form-control" name="type">
                    <option selected [ngValue]="undefined">Todos</option>
                    <option [ngValue]="true">{{'ativo'|translate}}</option>
                    <option [ngValue]="false">{{'statusNames.inactive'|translate}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">{{'Status'|translate}}</label>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end col-12  p-0 mb-3">
        <a href="javascript:void(0)" (click)="onFilter()" class="btn btn-search-content  btn-primary" type="button">
            <span class="ml-1">{{'buscar'|translate}}</span>
        </a>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'tipo'|translate}}</th>
                <th scope="col">{{'Titulo'|translate}}</th>
                <th scope="col">{{'novocadastro.criado'|translate}}</th>
                <th scope="col">{{'Autor'|translate}}</th>
                <th scope="col">{{'Status'|translate}}</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(listSearch.results)">
                <td class="text-center py-4" colspan="6">{{'nenhumresultado'|translate}}</td>
            </tr>
            <tr *ngFor="let l of listSearch.results |
                    paginate: {itemsPerPage: listSearch.pageSize,
                    currentPage: listSearch.currentPage,
                    totalItems: listSearch.totalCount}">
                <td class="text-capitalize">{{l.type}}</td>
                <td>{{l.title}}</td>
                <td>{{l.createdAt | date:'dd/MM/yyyy'}}</td>
                <td>{{l.user?.name}}</td>
                <td>
                    <!--{{l.status ? 'Ativo' : 'Inativo'}}-->
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="accept-terms2"
                               [checked]="l?.status"
                               [disabled]="true"
                        >
                        <label class="custom-control-label" for="accept-terms2"></label>
                    </div>
                </td>
                <td>
                    <div class="d-flex">
                        <button [routerLink]="['/content-detail/' + l.id]">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                        <button (click)="actionDelete(l?.id)">
                            <svg-icon src="assets/svg/delete-2.svg"></svg-icon>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
    <!--MODAL USER-->
    <div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal_header">
                    <h3 class="header-title">
                        {{'usuario'|translate}}
                    </h3>
                    <a href="javascript:void('');"
                       class="btn "
                       type="button" (click)="closeModal('modalUser')">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>
                </div>
                <div class="modal-body modal-body--small">
                    <div class="row my-3">
                        <!--NAME-->
                        <div class="col-4">
                            <div class="input-material">
                                <input id="nameUser" class="form-control" type="text"
                                       required maxlength="150"/>
                                <label for="nameUser">  {{'nome'|translate}}</label>
                            </div>
                        </div>
                        <!--RE-->
                        <div class="col-4">
                            <div class="input-material">
                                <input id="reUser" class="form-control" type="text"
                                       required maxlength="15"/>
                                <label for="reUser">RE</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-4">
                            <div class="input-material flex-grow-1">
                                <select id="profileUser" class="form-control" name="type">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option [ngValue]="'user'">{{'managersName.user' | translate}}</option>
                                    <option [ngValue]="'product-manager'">{{'managersName.product-manager' | translate}}</option>
                                    <option [ngValue]="'medical-manager'">{{'managersName.medical-manager' | translate}}</option>
                                    <option [ngValue]="'analyst'">{{'managersName.analyst' | translate}}</option>
                                    <option [ngValue]="'training-manager'">{{'managersName.training-manager' | translate}}</option>
                                    <option [ngValue]="'marketing-manager'">{{'managersName.marketing-manager' | translate}}</option>
                                </select>
                                <label for="profileUser" class="mb-0 mr-3">  {{'perfil'|translate}}</label>
                            </div>
                        </div>
                        <!--EMAIL-->
                        <div class="col-4 mt-3">
                            <div class="input-material">
                                <input id="userEmail" class="form-control" type="text"
                                       required maxlength="150"/>
                                <label for="userEmail">{{'email'|translate}}</label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-4 mt-3">
                            <div class="input-material flex-grow-1">
                                <select id="countryUser" class="form-control" name="type">

                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>

                                    <option>Teste</option>


                                </select>
                                <label for="countryUser" class="mb-0 mr-3">  {{'pais'|translate}}</label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <div class="col-4 mt-3">
                            <div class="input-material flex-grow-1">
                                <select id="unityUser" class="form-control" name="type">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option>}</option>

                                </select>
                                <label for="unityUser" class="mb-0 mr-3">  {{'unidade'|translate}}</label>
                            </div>

                        </div>
                    </div>
                    <button class="btn btn-block btn-warning mt-3">  {{'salvar'|translate}}</button>
                </div>

            </div>
        </div>
    </div>


</div>

