import {Component, AfterContentInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {
    Content,
    ContentsByTypeGQL,
    CreateThemeContentInput,
    CreateThemeGQL,
    CreateThemeInput,
    RemoveThemeGQL,
    SearchThemeGQL, SearchThemeInput,
    SearchThemeQuery,
    Theme,
    ThemePage,
    UpdateThemeGQL,
    UpdateThemeInput
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-themes',
    templateUrl: './themes.component.html',
    styleUrls: ['./themes.component.scss']
})
export class ThemesComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    filter: SearchThemeInput = new SearchThemeInput();
    listSearch: ThemePage = new ThemePage();
    theme: Theme = new Theme();
    searchThemeQuery: QueryRef<SearchThemeQuery>;
    code: string;
    content: Content[] = [];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private searchGriGQL: SearchThemeGQL,
                private updateThemeGQL: UpdateThemeGQL,
                private createThemeGQL: CreateThemeGQL,
                private removeThemeGQL: RemoveThemeGQL,
                public contentsByTypeGQL: ContentsByTypeGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngAfterContentInit(): void {
        this.filter.page = 1;
        this.loading.show();
        this.searchThemeQuery = this.searchGriGQL.watch({input: this.filter});
        this.searchThemeQuery.valueChanges.subscribe(({data}) => {
            this.listSearch = JSON.parse(JSON.stringify(data.searchTheme)) as ThemePage;
            this.loading.hide();
        }, error => super.onError(error));
    }

    ngOnDestroy(): void {
        this.destroyModal('modalTema');
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    onFilter() {
        this.loading.show();
        this.searchThemeQuery.refetch({input: this.filter}).finally(() => {
            this.loading.hide();
        });
    }

    getContent() {
        this.contentsByTypeGQL.watch({type: 'material', code: this.code}).valueChanges.subscribe(({data}) => {
            this.content = data.contentsByType as Content[];
            // FILTRA OS SELECIONADOS
            this.actionFilterContent();
        }, error => super.onError(error));
    }

    actionFilterContent() {
        this.theme.content.forEach((e) => {
            this.content = this.content.filter((f) => {
                return e.id != f.id;
            });
        });
    }

    removeContent(index: number) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.theme.content.splice(index, 1);
            this.getContent();
        });
    }

    addContent(c: Content) {
        this.theme.content.push(c);
        this.actionFilterContent();
        this.code = '';
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningDelete'), () => {
            this.removeThemeGQL.mutate({id}).subscribe((result) => {
                this.onFilter();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningDeleteSuccess'), 'success');
            });
        });
    }

    actionEdit(theme: Theme) {
        this.theme = Object.assign({}, theme);
        this.code = '';
        this.showModal('modalTema');
        this.getContent();
    }

    actionNew() {
        this.theme = new Theme();
        this.theme.content = [];
        this.code = '';
        this.getContent();
        this.showModal('modalTema');
    }

    onSave() {
        const arrValidateFields = [
            {value: this.theme.name, text: `Titulo<br>`},
            {value: this.theme.description, text: `${this.translate.instant('descricao')}<br>`},
            {value: this.theme.content, text: `Um conteúdo<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.theme.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionContent() {
        const createThemeContentInputs: CreateThemeContentInput[] = [];
        this.theme.content.forEach((e) => {
            createThemeContentInputs.push({id: e.id});
        });
        return createThemeContentInputs;
    }

    actionUpdate() {
        const input: UpdateThemeInput = {
            id: this.theme.id,
            name: this.theme.name,
            description: this.theme.description,
            content: this.actionContent(),
        };
        this.updateThemeGQL.mutate({input}).subscribe(({data}) => {
            this.closeModal('modalTema');
            this.onFilter();
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
        }, error => super.onError(error));
    }

    actionCreate() {
        const input: CreateThemeInput = {
            name: this.theme.name,
            description: this.theme.description,
            content: this.actionContent()
        };
        this.createThemeGQL.mutate({input}).subscribe(({data}) => {
            this.closeModal('modalTema');
            this.onFilter();
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningCreateSuccess'), 'success');
        }, error => super.onError(error));
    }
}
