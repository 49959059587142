import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../base/base.component";

declare var $: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent implements OnInit {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
       super (router, loading, translate);
    }

    ngOnInit(): void {
    }

}
