import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../base/base.component";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit {

  btnNew = false;

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public userService: UserService,
              public translate: TranslateService) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
  }

}
