<div class="body">
    <div class="header border-0">
        <div class="d-flex">
            <a href="javascript:history.back()" class="cursor-pointer">
                <svg-icon class="mr-5" src="assets/svg/back.svg"></svg-icon>
            </a>
            <h1 class="header-title">
                {{'conteudo'|translate}}
            </h1>
        </div>
    </div>
    <div class="container banner_container">
        <div class="page_padding">
            <div class="row align-items-end mb-md-2">
                <div class="col-md-3">
                    <div class="d-flex flex-column">
                        <div class="input-material">
                            <select id="type" [(ngModel)]="content.type" class="form-control text-capitalize" name="type">
                                <option selected [ngValue]="undefined">
                                    {{'minhalista.input3.subtitle' | translate}}
                                </option>
                                <option *ngFor="let t of typeContent" [value]="t">{{t}}</option>
                            </select>
                            <label for="type" class="mb-0 mr-3">{{'tipo'|translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <div class="input-material">
                            <select id="profile" [(ngModel)]="content.gri" class="form-control" name="type">
                                <option selected
                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let g of griCodes" [value]="g">{{g}}</option>
                            </select>
                            <label for="profile" class="mb-0 mr-3">GRI</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-material">
                        <input id="index" [(ngModel)]="content.index" appOnlyNumber class="form-control" type="text"
                               required maxlength="15"/>
                        <label for="index">Index</label>
                    </div>
                </div>
                <div *ngIf="!isNullOrUndefined(content.id)" class="col-md-3 offset-md-2">
                    <div class="card_beige d-flex justify-content-between align-items-center px-3">
                        <b>{{'statusativo'|translate}}</b>
                        <label class="switch mb-0">
                            <input type="checkbox" [(ngModel)]="content.status" name="checkbox">
                            <span class="slider round cursor-default"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-9">
                    <div class="input-material">
                        <input id="title" [(ngModel)]="content.title" class="form-control" type="text"
                               required maxlength="50"/>
                        <label for="title">{{'titulo'|translate}}</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-material">
                        <input id="reUser" [(ngModel)]="content.pag" class="form-control" type="text"
                               required maxlength="15"/>
                        <label for="reUser">{{'pedidorelatorio'|translate}}</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="ods_select">
                        <b>ODS</b>
                        <span>{{'selecioneODSdoconteudo'|translate}}</span>
                        <div class="ods_row">
                            <div class="ods_card" [ngClass]="{'active':o.isActive}"
                                 [style]="{'background':o.color}"
                                 (click)="selectedOds(i)" *ngFor="let o of odeses;let i = index">
                                <div class="d-flex align-items-start" style="gap: 8px">
                                    <p class="ods_card_number">{{o.number}}</p>
                                    <p class="ods_card_name">{{o.name}} </p>
                                </div>
                                <img class="ods_card_img" src="{{o.imageUrl}}" alt="ods">
                            </div>

                        </div>
                    </div>

                </div>
                <div class="col-12 mt-3">
                    <div class="card_beige w-100">
                        <b>{{'textodecorpo'|translate}}</b>
                        <ckeditor [(ngModel)]="content.content" [editor]="Editor"></ckeditor>
                    </div>
                </div>

            </div>

            <div class="row mt-5 mb-3">
                <div class="col-6">
                    <div class="d-flex justify-content-center w-100">
                        <a href="javascript:void(0)" routerLink="/content"
                           class="btn btn-outline-primary col-md-7 mt-3"
                           type="button">
                            <span class="ml-1">{{'cancelar'|translate}}</span>
                        </a>
                    </div>
                </div>
                <div class="col-6">
                    <div class="d-flex justify-content-center w-100">
                        <a href="javascript:void(0)" (click)="onSave()"
                           class="btn btn-primary col-md-7 mt-3"
                           type="button">
                            <span class="ml-1">{{'salvar'|translate}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>



